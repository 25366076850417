export const deepSearcher = (fields: Array<string>, query: string) => {
  return function matcher(object: any): any {
    const keys = Object.keys(object);
    return keys.some((key) => {
      const value = object[key];
      if (Array.isArray(value)) return value.some(matcher);
      if (value instanceof Object) return matcher(value);
      if (fields.includes(key)) {
        if (typeof value === "string")
          return value
            .trim()
            .toLowerCase()
            .includes(query.trim().toLowerCase());
        return (
          value.toString().trim().toLowerCase() ===
          query.toString().trim().toLowerCase()
        );
      }
      return false;
    });
  };
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (
  name: string,
  fontSize?: number,
  color?: string
) => {
  if (name.split(" ").length > 1) {
    return {
      sx: {
        bgcolor: color ? color : stringToColor(name),
        fontSize: fontSize ? fontSize : undefined,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return {
    sx: {
      bgcolor: color ? color : stringToColor(name),
      fontSize: fontSize ? fontSize : undefined,
    },
    children: `${name.split(" ")[0][0]}`,
  };
};

export function separadorMiles(n: number | string, fillZero: boolean) {
  // TODO: Mejorar codigo de mierda

  // if (!n) {
  //   if (fillZero) return "0";
  //   return "";
  // }
  const mappedString = n.toString().replace(".", ",").split(",");
  const decimal = mappedString.length > 1 && mappedString[1];

  // codigo estupido pa agragar un negativo asi rapido nomas, TODO: mejorar regex
  const sign = n.toString().includes("-") ? "-" : "";
  // Fin codigo estupido xd pd: ya no es tan estupido

  const montoSeparado = mappedString[0]
    .toString()
    .replace(/[-+]?[^0-9]/g, "") // RegExp para reemplazar todo lo que no sea numero
    .replace(".", "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // RegExp de separador de miles "."

  if (!decimal) {
    return sign + montoSeparado;
  } else {
    return sign + montoSeparado + "," + decimal;
  }
}

export function convertNumber(number: string) {
  return Number(
    number
      .toString()
      .replace(/[^0-9]/g, "")
      .replace(".", "")
  );
}

export function convertNumberToImput(number: string) {
  if (number.length === 0) return "";
  return Number(
    number
      .toString()
      .replace(/[^0-9]/g, "")
      .replace(".", "")
  );
}

export const replaceAll = (
  cadena: string,
  valor: string,
  reemplazo: string
) => {
  while (cadena.indexOf(valor) !== -1) {
    cadena = cadena.replace(valor, reemplazo);
  }
  return cadena;
};

/**
 * se encarga de descargar un archivo en formato  PDF
 * @param fileName Nombre del archivo
 * @param fileBase64 base64 del archivo
 */

export const downloadPdf = (fileName: string, fileBase64: string) => {
  var link = document.createElement("a");
  link.download = `${fileName}`;
  link.href = `data:application/pdf;base64,${fileBase64}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 *
 * @param file archivo
 * @returns retorna strging en formato base64
 */

export const getBase64 = (file: File) => {
  return new Promise<string>((resolve) => {
    let baseURL: string | ArrayBuffer | null;
    // Make new FileReader
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      if (typeof baseURL === "string") resolve(baseURL.split(",")[1]);
      resolve("");
    };
  });
};

export const formatearNumeroConSeparador = (input: string) => {
  // Eliminar caracteres no numéricos
  const numeros = input.replace(/[^0-9]/g, "");

  // Separar el número con comas para los miles
  const numeroFormateado = numeros.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Agregar el signo "-" si está presente al principio
  if (input.startsWith("-")) {
    return "-" + numeroFormateado;
  }

  return numeroFormateado;
};

export const quitarSeparadoresDeMilesYConvertirAEntero = (
  numeroFormateado: string
) => {
  // Quitar todos los separadores de miles (puntos) y convertir a número entero
  const numeroSinSeparadores = numeroFormateado.replace(/\./g, "");
  return parseInt(numeroSinSeparadores, 10);
};
