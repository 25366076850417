import { BoletaDePagoDto } from "../models/dto/BoletaDePago";
import { listarBoletasResponse } from "../models/response/boletasResponse";
import API from "./API";

export const listarBoletasDePago = async () => {
  try {
    const listarBoletasResponse = await API.get<listarBoletasResponse[]>(
      "/api/boletapago/listar"
    );
    if (listarBoletasResponse.status === 200) {
      return listarBoletasResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const modificarBoletaDePago = async (
  boleta: BoletaDePagoDto,
  id: number
) => {
  try {
    const modificarBoletaResponse = await API.put<string>(
      `/api/boletapago/${id}/modificar`,
      boleta
    );
    if (modificarBoletaResponse.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};

export const crearBoletaDePago = async (boleta: BoletaDePagoDto) => {
  try {
    const crearBoletaResponse = await API.post<string>(
      `/api/boletapago/crear`,
      boleta
    );
    if (crearBoletaResponse.status === 201) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
