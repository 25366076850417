import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { stringAvatar } from "../../../helpers/utils";
import {
  FormikPropsDatosUsuarios,
  UserLabelProps,
} from "../AdministrarImpuestos";

export interface LabelDto {
  id: number;
  label: string;
}
interface DatosUsuariosProps {
  values: FormikPropsDatosUsuarios;
  labelOptions: LabelDto[];
  readOnly: boolean;
  setValues: (value: FormikPropsDatosUsuarios) => void;
  hadleChangueUsuario: (user: UserLabelProps | null) => void;
  handleChangePeriodo: (periodo: string) => void;
  handleSubmit: () => void;
}

export const DatosUsuarios = ({
  values,
  labelOptions,
  readOnly,
  setValues,
  hadleChangueUsuario,
  handleChangePeriodo,
  handleSubmit,
}: DatosUsuariosProps) => {
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Administración de impuestos" />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <Autocomplete
                id="country-select-demo"
                fullWidth
                options={labelOptions}
                autoHighlight
                getOptionLabel={(option) => option.label}
                readOnly={readOnly}
                value={values.user}
                onChange={(e, value) => hadleChangueUsuario(value)}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  >
                    <Avatar
                      style={{ width: 30, height: 30 }}
                      {...stringAvatar(option.label, 12)}
                    />
                    <Box component="div" style={{ display: "flex" }}>
                      <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                        {option.label}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar usuario"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                id="outlined-basic-year"
                label="Periodo"
                variant="outlined"
                type="month"
                value={`${values.year}-${values.month}`}
                onChange={(e) => handleChangePeriodo(e.target.value)}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.status}
                  label="Estado"
                  onChange={(e) =>
                    setValues({ ...values, status: e.target.value })
                  }
                >
                  <MenuItem value={"active"}>Activo</MenuItem>
                  <MenuItem value={"inactive"}>Inactivo</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box
              component="div"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                size="large"
                startIcon={<Search />}
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
