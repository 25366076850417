import { IRPRspDto } from "../models/dto/IrpRsp.dto";
import API from "./API";
interface IrpRspResponse {
  id: number;
}
export const crearImpuestoIrpRsp = async (irpData: IRPRspDto) => {
  try {
    const crearIrpRspResponse = await API.post<IrpRspResponse>(
      `/api/irprsp/crear`,
      irpData
    );
    if (crearIrpRspResponse.status === 200) {
      return crearIrpRspResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const modificarImpuestoIrpRsp = async (
  irpData: IRPRspDto,
  id: string
) => {
  try {
    const crearIrpRspResponse = await API.put(
      `/api/irprsp/${id}/modificar`,
      irpData
    );
    if (crearIrpRspResponse.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
