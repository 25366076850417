import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  crearProductoImpuesto,
  productoImpuestosPorUsuario,
} from "../../apis/ApiProductoImpuestos";
import { TipoImpuestosEnum } from "../../models/enums/TipoImpuestos.enum";
import { UsuariosResponse } from "../../models/response/usuariosResponse";
import { RenderIf } from "../renderIf";

interface TipoImpuestoProps {
  idTImpuesto: number;
  isSelected: boolean;
}
interface FormikProps {
  resumenDetails: TipoImpuestoProps;
  irpGc: TipoImpuestoProps;
  irpRsp: TipoImpuestoProps;
  financial: TipoImpuestoProps;
}

interface CambiarPasswordProps {
  user: UsuariosResponse | null;
}
const EditarTiposIpuestos = ({ user }: CambiarPasswordProps) => {
  let { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const handlePress = async () => {
    setLoading(true);
    if (values.resumenDetails.isSelected) {
      await crearProductoImpuesto({
        idUser: Number(userId),
        idTImpuesto: values.resumenDetails.idTImpuesto,
      });
    }
    if (values.irpGc.isSelected) {
      await crearProductoImpuesto({
        idUser: user ? user.id : 0,
        idTImpuesto: values.irpGc.idTImpuesto,
      });
    }
    if (values.irpRsp.isSelected) {
      await crearProductoImpuesto({
        idUser: user ? user.id : 0,
        idTImpuesto: values.irpRsp.idTImpuesto,
      });
    }
    if (values.financial.isSelected) {
      await crearProductoImpuesto({
        idUser: user ? user.id : 0,
        idTImpuesto: values.financial.idTImpuesto,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialState = async () => {
    setApiLoading(true);
    const response = await productoImpuestosPorUsuario(userId ? userId : "");
    if (response) {
      response.map((item) => {
        if (item.idTImpuesto === TipoImpuestosEnum.RESUMEN_DETAILS) {
          values.resumenDetails.isSelected = true;
        }
        if (item.idTImpuesto === TipoImpuestosEnum.IRP_GC) {
          values.irpGc.isSelected = true;
        }
        if (item.idTImpuesto === TipoImpuestosEnum.IRP_RSP) {
          values.irpRsp.isSelected = true;
        }
        if (item.idTImpuesto === TipoImpuestosEnum.FINANCIAL) {
          values.financial.isSelected = true;
        }
        setValues(values);
        return item;
      });
    }
    setApiLoading(false);
  };
  const CambiarPasswordSchema = Yup.object().shape({});

  const { handleSubmit, setValues, values } = useFormik<FormikProps>({
    initialValues: {
      resumenDetails: {
        idTImpuesto: TipoImpuestosEnum.RESUMEN_DETAILS,
        isSelected: false,
      },
      irpGc: {
        idTImpuesto: TipoImpuestosEnum.IRP_GC,
        isSelected: false,
      },
      irpRsp: {
        idTImpuesto: TipoImpuestosEnum.IRP_RSP,
        isSelected: false,
      },
      financial: {
        idTImpuesto: TipoImpuestosEnum.FINANCIAL,
        isSelected: false,
      },
    },
    validationSchema: CambiarPasswordSchema,
    onSubmit: handlePress,
  });
  return (
    <>
      <RenderIf isTrue={apiLoading === false}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader subheader="" title="Editar tipos de impuestos" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      values.resumenDetails.isSelected ? (
                        <Checkbox defaultChecked />
                      ) : (
                        <Checkbox />
                      )
                    }
                    onClick={() =>
                      setValues({
                        ...values,
                        resumenDetails: {
                          ...values.resumenDetails,
                          isSelected: !values.resumenDetails.isSelected,
                        },
                      })
                    }
                    label="Iva"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      values.irpGc.isSelected ? (
                        <Checkbox defaultChecked />
                      ) : (
                        <Checkbox />
                      )
                    }
                    onClick={() =>
                      setValues({
                        ...values,
                        irpGc: {
                          ...values.irpGc,
                          isSelected: !values.irpGc.isSelected,
                        },
                      })
                    }
                    label="IRP GC"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      values.irpRsp.isSelected ? (
                        <Checkbox defaultChecked />
                      ) : (
                        <Checkbox />
                      )
                    }
                    onClick={() =>
                      setValues({
                        ...values,
                        irpRsp: {
                          ...values.irpRsp,
                          isSelected: !values.irpRsp.isSelected,
                        },
                      })
                    }
                    label="IRP RSP"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      values.financial.isSelected ? (
                        <Checkbox defaultChecked />
                      ) : (
                        <Checkbox />
                      )
                    }
                    label="Financial"
                    onClick={() =>
                      setValues({
                        ...values,
                        financial: {
                          ...values.financial,
                          isSelected: !values.financial.isSelected,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              Guardar
            </Button>
          </Box>
        </Card>
      </RenderIf>
      <RenderIf isTrue={apiLoading === true}>
        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
          }}
        >
          <CircularProgress />
        </Box>
      </RenderIf>
    </>
  );
};

export default EditarTiposIpuestos;
