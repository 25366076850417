import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";

interface SingUpProps {
  email: string;
  password: string;
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        www.dyr.com.py
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const LoginPage = () => {
  const { login } = useAuthentication();
  const [errorLoginText, setErrorLoginText] = useState<string | null>(null);
  const { loginLoading } = useSelector((state: RootState) => state.auth);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, "El minimo es de 2 caracteres")
      .max(50, "El maximo es de 50 caracteres")
      .required("Este campo es obligario")
      .email("Ingrese un correo valido"),
    password: Yup.string()
      .min(2, "El minimo es de 2 caracteres")
      .max(50, "El maximo es de 50 caracteres")
      .required("Este campo es obligario"),
  });
  const handlePress = async () => {
    const loginResponse = await login(values.email, values.password);
    if (loginResponse === false) {
      setErrorLoginText("usuario, contraseña o permisos incorrectos");
      setTimeout(() => {
        setErrorLoginText(null);
      }, 3000);
    }
  };
  const handleKeyDown = (onKeyDown: React.KeyboardEvent<HTMLDivElement>) => {
    if (onKeyDown.key === "Enter") handleSubmit();
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik<SingUpProps>({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: SignupSchema,
      onSubmit: handlePress,
    });

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: ` url(${require("../../images/logo.png")})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            DyR Admin
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              fullWidth
              label="Correo"
              margin="normal"
              name="email"
              onBlur={handleBlur("email")}
              onChange={handleChange("email")}
              onKeyDown={(event) => handleKeyDown(event)}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              fullWidth
              label="Contraseña"
              margin="normal"
              name="password"
              onBlur={handleBlur("password")}
              onChange={handleChange("password")}
              onKeyDown={(event) => handleKeyDown(event)}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errorLoginText && (
              <Typography style={{ fontSize: 12 }} color="primary">
                {errorLoginText}
              </Typography>
            )}
            <Box sx={{ py: 2 }}>
              <LoadingButton
                loading={loginLoading}
                loadingPosition="end"
                color="primary"
                endIcon={<LoginIcon />}
                fullWidth
                size="large"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Ingresar
              </LoadingButton>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
