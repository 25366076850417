import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { showAlert } from "../../../actions/alert.actions";
import {
  crearResumenDetails,
  modificarResumenDetails,
} from "../../../apis/ApiResumenDetails";
import {
  downloadPdf,
  formatearNumeroConSeparador,
  getBase64,
  quitarSeparadoresDeMilesYConvertirAEntero,
} from "../../../helpers/utils";
import { AlertPropsDto } from "../../../models/dto/AlertProps";
import { ListarTiposImpuestosResponse } from "../../../models/response/tipoImpuestosResponse";
import { AppDispatch } from "../../../store/store";
interface ResumenDetailsProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  status: string;
  impuesto: ListarTiposImpuestosResponse;
  setAlertProps: (alertProps: AlertPropsDto) => void;
}
interface FormikProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  vatDebt: string;
  vatCredit: string;
  previousBal: string;
  totalTax: string;
  totalInvoicedVat: string;
  periodWithHold: string;
  taxCreditPeriod: string;
  description: string;
  status: string;
  fileResumen: string;
  fileNameResumen: string;
  fileExtensionResumen: string;
  fileIvaReport: string;
  fileNameIvaReport: string;
  fileExtensionIvaReport: string;
}

export const ResumenDetails = ({
  id,
  idProducto,
  month,
  year,
  status,
  impuesto,
  setAlertProps,
}: ResumenDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtensionResumen: e.target.files[0].type,
        fileNameResumen: e.target.files[0].name,
        fileResumen: base64,
      });
    }
  };
  const handleFileReportChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtensionIvaReport: e.target.files[0].type,
        fileNameIvaReport: e.target.files[0].name,
        fileIvaReport: base64,
      });
    }
  };
  const handlePress = async () => {
    setLoading(true);
    if (values.description === "POST") {
      const response = await crearResumenDetails({
        idProducto: values.idProducto,
        month: values.month,
        year: values.year,
        vatDebt: quitarSeparadoresDeMilesYConvertirAEntero(values.vatDebt),
        vatCredit: quitarSeparadoresDeMilesYConvertirAEntero(values.vatCredit),
        previousBal: quitarSeparadoresDeMilesYConvertirAEntero(
          values.previousBal
        ),
        totalTax: quitarSeparadoresDeMilesYConvertirAEntero(values.totalTax),
        totalInvoicedVat: quitarSeparadoresDeMilesYConvertirAEntero(
          values.totalInvoicedVat
        ),
        periodWithHold: quitarSeparadoresDeMilesYConvertirAEntero(
          values.periodWithHold
        ),
        taxCreditPeriod: quitarSeparadoresDeMilesYConvertirAEntero(
          values.taxCreditPeriod
        ),
        description: "",
        status: values.status,
        fileResumen: values.fileResumen,
        fileNameResumen: values.fileNameResumen,
        fileExtensionResumen: values.fileExtensionResumen,
        fileExtensionIvaReport: values.fileExtensionIvaReport,
        fileIvaReport: values.fileIvaReport,
        fileNameIvaReport: values.fileNameIvaReport,
      });
      if (response) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
        setValues({
          ...values,
          description: "",
          id: response.id,
        });
      }
    } else {
      const modificarResponse = await modificarResumenDetails(
        {
          idProducto: values.idProducto,
          month: values.month,
          year: values.year,
          vatDebt: quitarSeparadoresDeMilesYConvertirAEntero(values.vatDebt),
          vatCredit: quitarSeparadoresDeMilesYConvertirAEntero(
            values.vatCredit
          ),
          previousBal: quitarSeparadoresDeMilesYConvertirAEntero(
            values.previousBal
          ),
          totalTax: quitarSeparadoresDeMilesYConvertirAEntero(values.totalTax),
          totalInvoicedVat: quitarSeparadoresDeMilesYConvertirAEntero(
            values.totalInvoicedVat
          ),
          periodWithHold: quitarSeparadoresDeMilesYConvertirAEntero(
            values.periodWithHold
          ),
          taxCreditPeriod: quitarSeparadoresDeMilesYConvertirAEntero(
            values.taxCreditPeriod
          ),
          description: "",
          status: values.status,
          fileResumen: values.fileResumen,
          fileNameResumen: values.fileNameResumen,
          fileExtensionResumen: values.fileExtensionResumen,
          fileExtensionIvaReport: values.fileExtensionIvaReport,
          fileIvaReport: values.fileIvaReport,
          fileNameIvaReport: values.fileNameIvaReport,
        },
        values.id.toString()
      );
      if (modificarResponse) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
      }
    }
    setLoading(false);
  };
  const ResumenDetailsSchema = Yup.object().shape({
    vatDebt: Yup.string().required("Este campo es requerido"),
    vatCredit: Yup.string().required("Este campo es requerido"),
    previousBal: Yup.string().required("Este campo es requerido"),
    totalTax: Yup.string().required("Este campo es requerido"),
    totalInvoicedVat: Yup.string().required("Este campo es requerido"),
    periodWithHold: Yup.string().required("Este campo es requerido"),
    taxCreditPeriod: Yup.string().required("Este campo es requerido"),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      id: impuesto.id,
      idProducto: impuesto.idProducto,
      month: month,
      year: year,
      vatDebt: impuesto.vatDebt!.toString(),
      vatCredit: impuesto.vatCredit!.toString(),
      previousBal: impuesto.previousBal!.toString(),
      totalTax: impuesto.totalTax!.toString(),
      totalInvoicedVat: impuesto.totalInvoicedVat!.toString(),
      periodWithHold: impuesto.periodWithHold!.toString(),
      taxCreditPeriod: impuesto.taxCreditPeriod!.toString(),
      description: impuesto.description ? impuesto.description.toString() : "",
      status: status,
      fileResumen: impuesto.fileResumen ? impuesto.fileResumen : "",
      fileNameResumen: impuesto.fileNameResumen ? impuesto.fileNameResumen : "",
      fileExtensionResumen: impuesto.fileExtensionResumen
        ? impuesto.fileExtensionResumen
        : "",
      fileIvaReport: impuesto.fileIvaReport ? impuesto.fileIvaReport : "",
      fileExtensionIvaReport: impuesto.fileExtensionIvaReport
        ? impuesto.fileExtensionIvaReport
        : "",
      fileNameIvaReport: impuesto.fileNameIvaReport
        ? impuesto.fileNameIvaReport
        : "",
    },
    validationSchema: ResumenDetailsSchema,
    onSubmit: handlePress,
  });
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Sección IVA" />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.vatDebt && errors.vatDebt)}
                helperText={touched.vatDebt && errors.vatDebt}
                label="IVA débito"
                onChange={handleChange("vatDebt")}
                onBlur={handleBlur("vatDebt")}
                required
                value={formatearNumeroConSeparador(values.vatDebt)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.vatCredit && errors.vatCredit)}
                helperText={touched.vatCredit && errors.vatCredit}
                label="IVA credito"
                onChange={handleChange("vatCredit")}
                onBlur={handleBlur("vatCredit")}
                required
                value={formatearNumeroConSeparador(values.vatCredit)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.previousBal && errors.previousBal)}
                helperText={touched.previousBal && errors.previousBal}
                label="Saldo de periodos anteriores"
                onChange={handleChange("previousBal")}
                onBlur={handleBlur("previousBal")}
                required
                value={formatearNumeroConSeparador(values.previousBal)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.totalTax && errors.totalTax)}
                helperText={touched.totalTax && errors.totalTax}
                label="Total IVA"
                onChange={handleChange("totalTax")}
                onBlur={handleBlur("totalTax")}
                required
                value={formatearNumeroConSeparador(values.totalTax)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.totalInvoicedVat && errors.totalInvoicedVat
                )}
                helperText={touched.totalInvoicedVat && errors.totalInvoicedVat}
                label="Total facturado (IVA incluido)"
                onChange={handleChange("totalInvoicedVat")}
                onBlur={handleBlur("totalInvoicedVat")}
                required
                value={formatearNumeroConSeparador(values.totalInvoicedVat)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.periodWithHold && errors.periodWithHold)}
                helperText={touched.periodWithHold && errors.periodWithHold}
                label="Retenciones del periodo"
                onChange={handleChange("periodWithHold")}
                onBlur={handleBlur("periodWithHold")}
                required
                value={formatearNumeroConSeparador(values.periodWithHold)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.taxCreditPeriod && errors.taxCreditPeriod
                )}
                helperText={touched.taxCreditPeriod && errors.taxCreditPeriod}
                label="Crédito fiscal acumulado"
                onChange={handleChange("taxCreditPeriod")}
                onBlur={handleBlur("taxCreditPeriod")}
                required
                value={formatearNumeroConSeparador(values.taxCreditPeriod)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                component="label"
                endIcon={<CloudUploadIcon />}
              >
                Subir archivo
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
              <Box sx={{ marginLeft: 1 }}>
                {values.fileResumen && (
                  <Button
                    onClick={() =>
                      downloadPdf(values.fileNameResumen, values.fileResumen)
                    }
                    variant="text"
                    size="small"
                  >
                    {values.fileNameResumen
                      ? values.fileNameResumen
                      : "Ver documento"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                endIcon={<CloudUploadIcon />}
              >
                Subir reporte
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={handleFileReportChange}
                />
              </Button>
              <Box sx={{ marginLeft: 1 }}>
                {values.fileResumen && (
                  <Button
                    onClick={() =>
                      downloadPdf(
                        values.fileNameIvaReport,
                        values.fileIvaReport
                      )
                    }
                    variant="text"
                    size="small"
                  >
                    {values.fileIvaReport
                      ? values.fileNameIvaReport
                      : "Ver documento"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};
