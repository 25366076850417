import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { showAlert } from "../../../actions/alert.actions";
import {
  crearImpuestoIrpGc,
  modificarImpuestoIrpGc,
} from "../../../apis/ApiIrpGc";
import {
  formatearNumeroConSeparador,
  quitarSeparadoresDeMilesYConvertirAEntero,
} from "../../../helpers/utils";
import { AlertPropsDto } from "../../../models/dto/AlertProps";
import { ListarTiposImpuestosResponse } from "../../../models/response/tipoImpuestosResponse";
import { AppDispatch } from "../../../store/store";
interface FormikProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  incomeCostsIncomeTax: string;
  capitalGainIncome: string;
  deductibleCost: string;
  presumedIncome: string;
  description: string;
  status: string;
}

interface SeccionIRPGCProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  status: string;
  alertProps: AlertPropsDto;
  impuesto: ListarTiposImpuestosResponse;
  setAlertProps: (alertProps: AlertPropsDto) => void;
}
export const SeccionIRPGC = ({
  id,
  idProducto,
  month,
  year,
  status,
  alertProps,
  impuesto,
  setAlertProps,
}: SeccionIRPGCProps) => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handlePress = async () => {
    setloading(true);
    if (values.description === "POST") {
      const response = await crearImpuestoIrpGc({
        id: id,
        idProducto: values.idProducto,
        month: values.month,
        year: values.year,
        incomeCostsIncomeTax: quitarSeparadoresDeMilesYConvertirAEntero(
          values.incomeCostsIncomeTax
        ),
        capitalGainIncome: quitarSeparadoresDeMilesYConvertirAEntero(
          values.capitalGainIncome
        ),
        deductibleCost: quitarSeparadoresDeMilesYConvertirAEntero(
          values.deductibleCost
        ),
        presumedIncome: quitarSeparadoresDeMilesYConvertirAEntero(
          values.presumedIncome
        ),
        description: "",
        status: values.status,
      });
      if (response) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
        setValues({
          ...values,
          description: "",
          id: response.id,
        });
      }
    } else {
      const modificarImpuesto = await modificarImpuestoIrpGc(
        {
          id: id,
          idProducto: values.idProducto,
          month: values.month,
          year: values.year,
          incomeCostsIncomeTax: quitarSeparadoresDeMilesYConvertirAEntero(
            values.incomeCostsIncomeTax
          ),
          capitalGainIncome: quitarSeparadoresDeMilesYConvertirAEntero(
            values.capitalGainIncome
          ),
          deductibleCost: quitarSeparadoresDeMilesYConvertirAEntero(
            values.deductibleCost
          ),
          presumedIncome: quitarSeparadoresDeMilesYConvertirAEntero(
            values.presumedIncome
          ),
          description: "",
          status: values.status,
        },
        values.id.toString()
      );
      if (modificarImpuesto) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
      }
    }
    setloading(false);
  };

  const IRPGCdSchema = Yup.object().shape({
    capitalGainIncome: Yup.string().required("Este campo es requerido"),
    deductibleCost: Yup.string().required("Este campo es requerido"),
    presumedIncome: Yup.string().required("Este campo es requerido"),
    incomeCostsIncomeTax: Yup.string().required("Este campo es requerido"),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      id: impuesto.id,
      idProducto: impuesto.idProducto,
      month: month,
      year: year,
      incomeCostsIncomeTax: impuesto.incomeCostsIncomeTax!.toString(),
      capitalGainIncome: impuesto.capitalGainIncome!.toString(),
      deductibleCost: impuesto.deductibleCost!.toString(),
      presumedIncome: impuesto.presumedIncome!.toString(),
      description: impuesto.description!,
      status: status,
    },
    validationSchema: IRPGCdSchema,
    onSubmit: handlePress,
  });
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Sección IRP-GC" />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.capitalGainIncome && errors.capitalGainIncome
                )}
                helperText={
                  touched.capitalGainIncome && errors.capitalGainIncome
                }
                label="Ingresos de capital (IVA incluido)"
                onChange={handleChange("capitalGainIncome")}
                onBlur={handleBlur("capitalGainIncome")}
                required
                value={formatearNumeroConSeparador(values.capitalGainIncome)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.deductibleCost && errors.deductibleCost)}
                helperText={touched.deductibleCost && errors.deductibleCost}
                label="Costos y gastos deducibles"
                onChange={handleChange("deductibleCost")}
                onBlur={handleBlur("deductibleCost")}
                required
                value={formatearNumeroConSeparador(values.deductibleCost)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.presumedIncome && errors.presumedIncome)}
                helperText={touched.presumedIncome && errors.presumedIncome}
                label="Renta presunta"
                onChange={handleChange("presumedIncome")}
                onBlur={handleBlur("presumedIncome")}
                required
                value={formatearNumeroConSeparador(values.presumedIncome)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.incomeCostsIncomeTax && errors.incomeCostsIncomeTax
                )}
                helperText={
                  touched.incomeCostsIncomeTax && errors.incomeCostsIncomeTax
                }
                label="Estimativo IRP-GC del mes"
                onChange={handleChange("incomeCostsIncomeTax")}
                onBlur={handleBlur("incomeCostsIncomeTax")}
                required
                value={formatearNumeroConSeparador(values.incomeCostsIncomeTax)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};
