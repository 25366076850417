import {
  CearSolicitudBajaDto,
  ModificarSolicitudDto,
} from "../models/dto/SolicitudBaja.dto";
import {
  SolicitudBajaCrearResponse,
  SolicitudesBajasListResponse,
} from "../models/response/SolicitudesBajasResponse";
import API from "./API";

export const listarSolicitudesBaja = async () => {
  try {
    const solicitudesBajasList = await API.get<SolicitudesBajasListResponse[]>(
      "/api/solicitudbaja/listar"
    );
    if (solicitudesBajasList.status === 200) return solicitudesBajasList.data;
    return null;
  } catch {
    return null;
  }
};

export const listarSolicitudesBajaById = async (id: string) => {
  try {
    const solicitudesBajasList = await API.get<SolicitudesBajasListResponse>(
      `/api/solicitudbaja/${id}/datos`
    );
    if (solicitudesBajasList.status === 200) return solicitudesBajasList.data;
    return null;
  } catch {
    return null;
  }
};

export const crearSolicitudBaja = async (solicitud: CearSolicitudBajaDto) => {
  try {
    const solicitudesBajasList = await API.post<SolicitudBajaCrearResponse>(
      `/api/solicitudbaja/crear`,
      solicitud
    );
    if (solicitudesBajasList.status === 200) return solicitudesBajasList.data;
    return null;
  } catch {
    return null;
  }
};

export const modificarSolicitudBaja = async (
  id: number,
  solicitud: ModificarSolicitudDto
) => {
  try {
    const solicitudesBajasList = await API.put(
      `/api/solicitudbaja/${id}/modificar`,
      solicitud
    );
    if (solicitudesBajasList.status === 202) return true;
    return null;
  } catch {
    return null;
  }
};

export const eliminarSolicitudBaja = async (id: string) => {
  try {
    const solicitudesBajasList = await API.delete(
      `/api/solicitudbaja/${id}/eliminar`
    );
    if (solicitudesBajasList.status === 200) return true;
    return null;
  } catch {
    return null;
  }
};
