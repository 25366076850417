import SendIcon from "@mui/icons-material/Send";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { showAlert } from "../../actions/alert.actions";
import { enviarNotificacion } from "../../apis/ApiUsuarios";
import { AlertModal } from "../../components/AlertModal";
import { stringAvatar } from "../../helpers/utils";
import { AppDispatch, RootState } from "../../store/store";

export interface UserLabelProps {
  id: number;
  label: string;
}
interface FormikValues {
  usuario: UserLabelProps | null;
  title: string;
  descripcion: string;
}
const RecordarImpuestosPage = () => {
  const { userList } = useSelector((state: RootState) => state.userData);
  const { alert } = useSelector((state: RootState) => state.alert);
  const [labelOptions, setLabelOptions] = useState([{ id: 0, label: "" }]);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialState = () => {
    if (userList) {
      //      setReadOnly(false);
      let arr: any = [];
      userList &&
        userList.map((user, index) => {
          return arr.push({ id: user.id, label: user.username });
        });
      setLabelOptions([...arr]);
    }
  };

  const UsuarioSelectedSchema = Yup.object().shape({
    usuario: Yup.object().required("Favor seleccione un usuario").nullable(),
    title: Yup.string()
      .required("Este campo es obligatorio")
      .max(80, "El máximo es de 80 caracteres"),
    descripcion: Yup.string()
      .required("Este campo es obligatorio")
      .max(80, "El máximo es de 140 caracteres"),
  });
  const hadleChangueUsuario = async (user: UserLabelProps | null) => {
    if (!user) return;
    setValues({ ...values, usuario: user });
  };
  const handlePress = async () => {
    const findUser =
      userList && userList.find((user) => user.id === values.usuario?.id);
    if (!findUser) return;
    setLoading(true)
    const response = await enviarNotificacion({
      token: findUser?.firebaseToken ? findUser.firebaseToken : "",
      titulo: values.title,
      mensaje: values.descripcion,
    });
    if (response) {
      dispatch(
        showAlert({
          showAlert: true,
          title: "Mensaje enviado exitosamente!",
          type: "success",
        })
      );
    }
    setLoading(false)

  };
  const {
    setValues,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik<FormikValues>({
    initialValues: {
      usuario: null,
      title: "",
      descripcion: "",
    },
    validationSchema: UsuarioSelectedSchema,
    onSubmit: handlePress,
  });
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        {alert.showAlert && (
          <AlertModal type={alert.type} title={alert.title} />
        )}
        <Card>
          <CardHeader title="Notificación de recordatorio de impuestos" />
          <Divider />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item md={6} sm={12}>
                <Box>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={labelOptions}
                    autoHighlight
                    disabled={loading}
                    getOptionLabel={(option) => option.label}
                    readOnly={false}
                    value={values.usuario}
                    onChange={(e, value) => hadleChangueUsuario(value)}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      >
                        <Avatar
                          style={{ width: 30, height: 30 }}
                          {...stringAvatar(option.label, 12)}
                        />
                        <Box component="div" style={{ display: "flex" }}>
                          <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                            {option.label}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seleccionar usuario"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                    placeholder="Titulo de notificación"
                    fullWidth
                    disabled={loading}
                    value={values.title}
                  />
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <Box>
                  <TextField
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    helperText={touched.descripcion && errors.descripcion}
                    placeholder="Cuerpo de notificación"
                    onChange={handleChange("descripcion")}
                    onBlur={handleBlur("descripcion")}
                    disabled={loading}
                    fullWidth
                    multiline
                    minRows={5}
                    value={values.descripcion}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              variant="contained"
              endIcon={<SendIcon />}
              disabled={loading}
            >
              Enviar
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default RecordarImpuestosPage;
