import { IrpGcDto } from "../models/dto/IrpGc.dto";
import API from "./API";

interface IrpGcResponse {
  id: number;
}
export const crearImpuestoIrpGc = async (irpData: IrpGcDto) => {
  try {
    const tipoImpuestosResponse = await API.post<IrpGcResponse>(
      `/api/irpgc/crear`,
      irpData
    );
    if (tipoImpuestosResponse.status === 200) {
      return tipoImpuestosResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const modificarImpuestoIrpGc = async (irpData: IrpGcDto, id: string) => {
  try {
    const tipoImpuestosResponse = await API.put(
      `/api/irpgc/${id}/modificar`,
      irpData
    );
    if (tipoImpuestosResponse.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
