import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { modificarSolicitudBaja } from "../apis/ApiSolicitudBaja";
import { AlertPropsDto } from "../models/dto/AlertProps";
import { SolicitudesBajasListResponse } from "../models/response/SolicitudesBajasResponse";
import { RootState } from "../store/store";

interface ModalUsuarioProps {
  open: boolean;
  solicitudDeBaja: SolicitudesBajasListResponse | null;
  alertProps: AlertPropsDto;
  setAlertProps: (alertProps: AlertPropsDto) => void;
  handleClose: () => void;
}

export interface FormikProps {
  usuarioModifica: number;
  descripcion: string;
  estado: string;
}

export const ModalEditarSolicitudDeBaja = ({
  open,
  solicitudDeBaja,
  alertProps,
  setAlertProps,
  handleClose,
}: ModalUsuarioProps) => {
  const [loading, setLoading] = useState(false);
  const sm = useMediaQuery("(min-width:700px)");
  const { userData } = useSelector((state: RootState) => state.auth);

  const handlePress = async () => {
    setLoading(true);
    const response = await modificarSolicitudBaja(
      solicitudDeBaja?.id ? solicitudDeBaja.id : 0,
      values
    );
    if (response) {
      setAlertProps({
        type: "success",
        showAlert: true,
        title: "Boleta de pago modificada exitosamente.",
      });
    } else {
      setAlertProps({
        type: "error",
        showAlert: true,
        title: "Algo salió mal, intente nuevamente.",
      });
    }
    setTimeout(() => {
      setAlertProps({
        type: "success",
        showAlert: false,
        title: "",
      });
    }, 3000);
    handleClose();
    setLoading(false);
  };
  const handleChangeEstado = (e: string | number | null) => {
    if (e) {
      setValues({
        ...values,
        estado: e.toString(),
      });
    }
  };
  const EditarBoletaDePagoSchema = Yup.object().shape({
    description: Yup.string().max(250, "El máximo es de 250 caracteres"),
  });
  useEffect(() => {
    setValues({
      ...values,
      usuarioModifica: userData?.usuario.id ? userData.usuario.id : 0,
      descripcion: solicitudDeBaja?.descripcion
        ? solicitudDeBaja?.descripcion
        : "",
      estado: solicitudDeBaja?.estado ? solicitudDeBaja.estado : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitudDeBaja]);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      usuarioModifica: 0,
      descripcion: "",
      estado: "",
    },
    validationSchema: EditarBoletaDePagoSchema,
    onSubmit: handlePress,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={sm ? style : styleResponsive}>
        <CardHeader
          title="Editar boleta de pago"
          style={{ padding: 18, paddingLeft: 24 }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                value={solicitudDeBaja?.nombreSolicita}
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                value={solicitudDeBaja?.userName}
                id="outlined-basic"
                label="Usuario"
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 3 }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onBlur={handleBlur("idPerfil")}
                  onChange={(e) => handleChangeEstado(e.target.value)}
                  value={values.estado}
                  label="Estado"
                  disabled={loading}
                >
                  <MenuItem value="active">Activo</MenuItem>
                  <MenuItem value="inactive">Inactivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 3 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                error={Boolean(touched.descripcion && errors.descripcion)}
                helperText={touched.descripcion && errors.descripcion}
                onBlur={handleBlur("descripcion")}
                onChange={handleChange("descripcion")}
                value={values.descripcion}
                id="outlined-basic"
                label="Descripcion"
                variant="outlined"
                disabled={true}
                minRows={4}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          component="div"
          style={{
            display: "flex",
            padding: 12,
            justifyContent: "end",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleClose()}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
};
const styleResponsive = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  margin: "auto",
};
