import { Circle, Edit } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getListadoUsuarios } from "../../actions/userData.actions";
import ErrorApi from "../../components/errorApi";
import { RenderIf } from "../../components/renderIf";
import { deepSearcher, stringAvatar } from "../../helpers/utils";
import { UsuariosResponse } from "../../models/response/usuariosResponse";
import { AppDispatch, RootState } from "../../store/store";
import "../home/Home.css";

const AdministraUsuariosPage = () => {
  const PERFIL_USUARIOS = 2;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { userList, loadingUserData } = useSelector(
    (state: RootState) => state.userData
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState<UsuariosResponse[] | null>(
    null
  );
  const [userSearch, setUserSearch] = useState<UsuariosResponse[] | null>(null);

  useEffect(() => {
    if (userList) initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  const initialState = () => {
    if (userList) {
      let arr: any = [];
      const users = userList.filter(
        (user) => user.idPerfil === PERFIL_USUARIOS
      );
      users &&
        users.map((user, index) => {
          return arr.push({ id: index, label: user.name });
        });
      setFilteredItems(users);
      setUserSearch(users);
    }
  };

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    const filteredItems =
      userSearch &&
      userSearch.filter(
        deepSearcher(["name", "username", "email", "estado", "telefono"], query)
      );
    setFilteredItems(filteredItems);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 8,
      }}
      component="main"
    >
      <Container maxWidth="lg">
        <RenderIf isTrue={loadingUserData === false}>
          <>
            <RenderIf isTrue={userList !== null}>
              <>
                <Card style={{ marginBottom: 10 }}>
                  <CardContent>
                    <Grid container style={{ marginBottom: 16 }}>
                      <Grid lg={4} md={12} item>
                        <TextField
                          placeholder="Buscar..."
                          size="small"
                          value={searchQuery}
                          onChange={(event) =>
                            onChangeSearch(event.target.value)
                          }
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<RefreshIcon />}
                          style={{ marginLeft: 10 }}
                          onClick={() => dispatch(getListadoUsuarios())}
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Grid container spacing={3}>
                  {filteredItems &&
                    filteredItems.map((user, index) => (
                      <Grid item lg={4} md={6} xs={12} sm={6} key={index}>
                        <Card>
                          <CardContent>
                            <Box
                              component="div"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                              className=""
                            >
                              <Avatar
                                {...stringAvatar(user.name)}
                                style={{
                                  justifyContent: "center",
                                  margin: "auto",
                                }}
                              />
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  textAlign: "center",
                                  marginTop: 10,
                                }}
                              >
                                {user.name}
                              </Typography>
                              <Box
                                className=""
                                component="div"
                                style={{ marginTop: 10, marginBottom: 10 }}
                              >
                                <Grid
                                  container
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <Grid md={6} item>
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: 14,
                                      }}
                                    >
                                      Usuario
                                    </Typography>
                                  </Grid>
                                  <Grid md={6} item>
                                    <Box component="div" className="colum-left">
                                      <Typography
                                        style={{ fontSize: 14 }}
                                        textAlign="end"
                                      >
                                        {user.username}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <Grid md={6} item>
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: 14,
                                      }}
                                    >
                                      Estado
                                    </Typography>
                                  </Grid>
                                  <Grid md={6} item>
                                    <Box component="div" className="colum-left">
                                      <Typography
                                        style={{ fontSize: 14 }}
                                        textAlign="end"
                                      >
                                        <Circle
                                          style={{ width: 10, height: 10 }}
                                          color={
                                            user.estado === "active"
                                              ? "secondary"
                                              : "primary"
                                          }
                                        />
                                        {user.estado === "active"
                                          ? "Activo"
                                          : "Inactivo"}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  className=""
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <Grid md={6} className="" item>
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: 14,
                                      }}
                                    >
                                      Correo
                                    </Typography>
                                  </Grid>
                                  <Grid md={6} item>
                                    <Box component="div" className="colum-left">
                                      <Typography
                                        style={{
                                          fontSize: 14,
                                        }}
                                        textAlign="end"
                                      >
                                        {user.email}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <Grid md={6} item>
                                    <Typography
                                      style={{
                                        fontWeight: "600",
                                        fontSize: 14,
                                      }}
                                    >
                                      Telefono
                                    </Typography>
                                  </Grid>
                                  <Grid md={6} item>
                                    <Typography
                                      style={{ fontSize: 14 }}
                                      textAlign="end"
                                    >
                                      {user.telefono}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            <Button
                              variant="contained"
                              fullWidth
                              size="small"
                              style={{ marginBottom: 5 }}
                              endIcon={<ArticleIcon />}
                              onClick={() =>
                                navigate(`/AdministarImpuestos/${user.id}`)
                              }
                            >
                              Administar impuestos
                            </Button>
                            <Button
                              variant="outlined"
                              fullWidth
                              size="small"
                              endIcon={<Edit />}
                              onClick={() =>
                                navigate(`/EditarUsuario/${user.id}`)
                              }
                            >
                              Editar Usuario
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </>
            </RenderIf>
            <RenderIf isTrue={userList === null}>
              <ErrorApi handleClick={() => dispatch(getListadoUsuarios())} />
            </RenderIf>
          </>
        </RenderIf>
        <RenderIf isTrue={loadingUserData === true}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              alignContent: "center",
              marginY: "auto",
            }}
            component="div"
          >
            <CircularProgress />
          </Box>
        </RenderIf>
      </Container>
    </Box>
  );
};

export default AdministraUsuariosPage;
