import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { showAlert } from "../../../actions/alert.actions";
import { crearFinancial, modificarFinancial } from "../../../apis/ApiFinancial";
import {
  formatearNumeroConSeparador,
  quitarSeparadoresDeMilesYConvertirAEntero,
} from "../../../helpers/utils";
import { AlertPropsDto } from "../../../models/dto/AlertProps";
import { ListarTiposImpuestosResponse } from "../../../models/response/tipoImpuestosResponse";
import { AppDispatch } from "../../../store/store";
interface SeccionIRPGCProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  status: string;
  impuesto: ListarTiposImpuestosResponse;
  setAlertProps: (alertProps: AlertPropsDto) => void;
}
interface FormikProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  finIncomeVatInclude: string;
  finExpenseInvest: string;
  finVatTreasury: string;
  finIncomeGross: string;
  finReceived: string;
  finPersonalIncomeTaxRsp: string;
  finPersonalIncomeTaxGc: string;
  finEstimateIncomeIrp: string;
  finEstimateReturns: string;
  description: string;
  estado: string;
}
export const SeccionFinancial = ({
  id,
  idProducto,
  month,
  year,
  status,
  impuesto,
  setAlertProps,
}: SeccionIRPGCProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handlePress = async () => {
    setLoading(true);
    if (values.description === "POST") {
      const response = await crearFinancial({
        idProducto: values.idProducto,
        month: values.month,
        year: values.year,
        finIncomeVatInclude: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finIncomeVatInclude
        ),
        finExpenseInvest: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finExpenseInvest
        ),
        finVatTreasury: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finVatTreasury
        ),
        finIncomeGross: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finIncomeGross
        ),
        finReceived: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finReceived
        ),
        finPersonalIncomeTaxRsp: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finPersonalIncomeTaxRsp
        ),
        finPersonalIncomeTaxGc: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finPersonalIncomeTaxGc
        ),
        finEstimateIncomeIrp: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finEstimateIncomeIrp
        ),
        finEstimateReturns: quitarSeparadoresDeMilesYConvertirAEntero(
          values.finEstimateReturns
        ),
        description: "",
        estado: values.estado,
      });
      if (response) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
        setValues({
          ...values,
          description: "",
          id: response.id,
        });
      }
    } else {
      const modificarImpuesto = await modificarFinancial(
        {
          idProducto: values.idProducto,
          month: values.month,
          year: values.year,
          finIncomeVatInclude: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finIncomeVatInclude
          ),
          finExpenseInvest: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finExpenseInvest
          ),
          finVatTreasury: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finVatTreasury
          ),
          finIncomeGross: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finIncomeGross
          ),
          finReceived: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finReceived
          ),
          finPersonalIncomeTaxRsp: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finPersonalIncomeTaxRsp
          ),
          finPersonalIncomeTaxGc: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finPersonalIncomeTaxGc
          ),
          finEstimateIncomeIrp: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finEstimateIncomeIrp
          ),
          finEstimateReturns: quitarSeparadoresDeMilesYConvertirAEntero(
            values.finEstimateReturns
          ),
          description: values.description,
          estado: values.estado,
        },
        values.id.toString()
      );
      if (modificarImpuesto) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
      }
    }
    setLoading(false);
  };
  const IRPGCdSchema = Yup.object().shape({
    finIncomeVatInclude: Yup.string().required("Este campo es requerido"),
    finExpenseInvest: Yup.string().required("Este campo es requerido"),
    finVatTreasury: Yup.string().required("Este campo es requerido"),
    finIncomeGross: Yup.string().required("Este campo es requerido"),
    finReceived: Yup.string().required("Este campo es requerido"),
    finPersonalIncomeTaxRsp: Yup.string().required("Este campo es requerido"),
    finPersonalIncomeTaxGc: Yup.string().required("Este campo es requerido"),
    finEstimateIncomeIrp: Yup.string().required("Este campo es requerido"),
    finEstimateReturns: Yup.string().required("Este campo es requerido"),
    description: Yup.string().max(250, "El máximo es de 250 caracteres"),
  });
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      id: impuesto.id,
      idProducto: impuesto.idProducto,
      month: month,
      year: year,
      finIncomeVatInclude: impuesto.fin_income_vat_include!.toString(),
      finExpenseInvest: impuesto.fin_expense_invest!.toString(),
      finVatTreasury: impuesto.fin_vat_treasury!.toString(),
      finIncomeGross: impuesto.fin_income_gross!.toString(),
      finReceived: impuesto.fin_received!.toString(),
      finPersonalIncomeTaxRsp: impuesto.fin_personal_income_tax_rsp!.toString(),
      finPersonalIncomeTaxGc: impuesto.fin_personal_income_tax_gc!.toString(),
      finEstimateIncomeIrp: impuesto.fin_estimate_income_irp!.toString(),
      finEstimateReturns: impuesto.fin_estimate_returns!.toString(),
      description: impuesto.description!.toString(),
      estado: status,
    },
    validationSchema: IRPGCdSchema,
    onSubmit: handlePress,
  });

  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Sección Financiera" />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finIncomeVatInclude && errors.finIncomeVatInclude
                )}
                helperText={
                  touched.finIncomeVatInclude && errors.finIncomeVatInclude
                }
                label="Ingresos (IVA incluido)"
                onChange={handleChange("finIncomeVatInclude")}
                onBlur={handleBlur("finIncomeVatInclude")}
                required
                value={formatearNumeroConSeparador(values.finIncomeVatInclude)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finExpenseInvest && errors.finExpenseInvest
                )}
                helperText={touched.finExpenseInvest && errors.finExpenseInvest}
                label="Egresos e inversiones (Sin IVA)"
                onChange={handleChange("finExpenseInvest")}
                onBlur={handleBlur("finExpenseInvest")}
                required
                value={formatearNumeroConSeparador(values.finExpenseInvest)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.finVatTreasury && errors.finVatTreasury)}
                helperText={touched.finVatTreasury && errors.finVatTreasury}
                label="IVA a ingresar al fisco"
                onChange={handleChange("finVatTreasury")}
                onBlur={handleBlur("finVatTreasury")}
                required
                value={formatearNumeroConSeparador(values.finVatTreasury)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.finIncomeGross && errors.finIncomeGross)}
                helperText={touched.finIncomeGross && errors.finIncomeGross}
                label="Renta tras IVA (Menos IVA pagado)"
                onChange={handleChange("finIncomeGross")}
                onBlur={handleBlur("finIncomeGross")}
                required
                value={formatearNumeroConSeparador(values.finIncomeGross)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.finReceived && errors.finReceived)}
                helperText={touched.finReceived && errors.finReceived}
                label="Ingreso neto"
                onChange={handleChange("finReceived")}
                onBlur={handleBlur("finReceived")}
                required
                value={formatearNumeroConSeparador(values.finReceived)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finPersonalIncomeTaxRsp &&
                    errors.finPersonalIncomeTaxRsp
                )}
                helperText={
                  touched.finPersonalIncomeTaxRsp &&
                  errors.finPersonalIncomeTaxRsp
                }
                label="Impuesto a la renta personal (RSP)"
                onChange={handleChange("finPersonalIncomeTaxRsp")}
                onBlur={handleBlur("finPersonalIncomeTaxRsp")}
                required
                value={formatearNumeroConSeparador(
                  values.finPersonalIncomeTaxRsp
                )}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finPersonalIncomeTaxGc &&
                    errors.finPersonalIncomeTaxGc
                )}
                helperText={
                  touched.finPersonalIncomeTaxGc &&
                  errors.finPersonalIncomeTaxGc
                }
                label="Impuesto a la renta personal (GC)"
                onChange={handleChange("finPersonalIncomeTaxGc")}
                onBlur={handleBlur("finPersonalIncomeTaxGc")}
                required
                value={formatearNumeroConSeparador(
                  values.finPersonalIncomeTaxGc
                )}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finEstimateIncomeIrp && errors.finEstimateIncomeIrp
                )}
                helperText={
                  touched.finEstimateIncomeIrp && errors.finEstimateIncomeIrp
                }
                label="Ingreso estimado después del IRP"
                onChange={handleChange("finEstimateIncomeIrp")}
                onBlur={handleBlur("finEstimateIncomeIrp")}
                required
                value={formatearNumeroConSeparador(values.finEstimateIncomeIrp)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.finEstimateReturns && errors.finEstimateReturns
                )}
                helperText={
                  touched.finEstimateReturns && errors.finEstimateReturns
                }
                label="Rentabilidad estimativa tras IRP (Porcentaje)"
                onChange={handleChange("finEstimateReturns")}
                onBlur={handleBlur("finEstimateReturns")}
                required
                value={formatearNumeroConSeparador(values.finEstimateReturns)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};
