import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsuariosResponse } from "../models/response/usuariosResponse";

export interface UserDataState {
  userList: UsuariosResponse[] | null;
  loadingUserData: boolean;
}

const initialState: UserDataState = {
  userList: null,
  loadingUserData: false,
};

type userDataAction =
  | { type: "[SET] user list data"; payload: UsuariosResponse[] | null }
  | { type: "[SET] user list loading"; payload: boolean };

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<userDataAction>) => {
      switch (action.payload.type) {
        case "[SET] user list data":
          return {
            ...state,
            userList: action.payload.payload,
          };
        case "[SET] user list loading":
          return {
            ...state,
            loadingUserData: action.payload.payload,
          };
      }
    },
  },
});

export const { setUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
