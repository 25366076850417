import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { stringAvatar } from "../../helpers/utils";
import { RootState } from "../../store/store";
import { theme } from "../../theme/index";

interface DashboardNavbarProps {
  //isSidebarOpen: boolean
  onSidebarOpen: () => void;
}
const DashboardNavbarRoot = styled(AppBar)(() => ({
  backgroundColor: theme?.palette.background.paper,
  boxShadow: theme?.shadows[3],
}));

export const DashboardNavbar = ({ onSidebarOpen }: DashboardNavbarProps) => {
  const { userData } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: "calc(100% - 280px)",
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Typography color="black">
            Bienvenido, {userData?.usuario.name}{" "}
          </Typography>
          <Avatar
            {...stringAvatar(
              userData?.usuario ? userData?.usuario.name.toUpperCase() : "",
              undefined,
              "#D22630"
            )}
            style={{
              justifyContent: "center",
              margin: "auto",
              marginLeft: "10px",
            }}
          />
          <Typography
            style={{
              fontWeight: "600",
              textAlign: "center",
              marginTop: 10,
            }}
          ></Typography>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
