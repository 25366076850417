import { Box, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import { DashboardSidebar } from "./dashboadSidebar";
import { DashboardNavbar } from "./dashboardNavbar";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 20,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

export const DasboardLayout = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const theme = useTheme();

  return (
    <DashboardLayoutRoot>
      <Box
        style={{ backgroundColor: theme.palette.background.default }}
        sx={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {children}
      </Box>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </DashboardLayoutRoot>
  );
};
