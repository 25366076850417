import { Container } from "@mui/material";
import { Box } from "@mui/system";
import "../home/Home.css";
const logo = require("../../images/logo.png");
const Home = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 8,
      }}
      component="main"
    >
      <Container maxWidth="lg">
        <Box
          component="div"
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100vh)",
          }}
        >
          <img src={logo} alt="logo dyr" style={{ width: "100%" }} />
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
