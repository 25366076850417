import { listarUsuarios } from "../apis/ApiUsuarios";
import { setUserData } from "../reducers/userData.reducer";
import { AppDispatch } from "../store/store";

export const getListadoUsuarios = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(
      setUserData({
        type: "[SET] user list loading",
        payload: true,
      })
    );
    const usuariosResponse = await listarUsuarios();
    if (usuariosResponse != null) {
      dispatch(
        setUserData({
          type: "[SET] user list data",
          payload: usuariosResponse,
        })
      );
    } else {
      dispatch(
        setUserData({
          type: "[SET] user list data",
          payload: null,
        })
      );
    }
    dispatch(
      setUserData({
        type: "[SET] user list loading",
        payload: false,
      })
    );
  };
};
