import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { modificarPassword } from "../../apis/ApiUsuarios";
import { UsuariosResponse } from "../../models/response/usuariosResponse";

interface FormikProps {
  password: string;
  confirmPassword: string;
}

interface CambiarPasswordProps {
  user: UsuariosResponse | null;
}
const CambiarPassword = ({ user }: CambiarPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePress = async () => {
    setLoading(true);
    await modificarPassword({
      id: user ? user.id : 0,
      newPassword: values.password,
    });
    setLoading(false);
  };

  const CambiarPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Favor ingresar contraseña")
      .min(6, "La contraseña debe tener al menos 6 caracteres")
      .max(12, "La contraseña debe tener hasta 12 caracteres"),
    confirmPassword: Yup.string()
      .required("Favor ingresar contraseña")
      .min(6, "La contraseña debe tener al menos 6 caracteres")
      .max(12, "La contraseña debe tener hasta 12 caracteres")
      .test("match-password", "Las contraseñas no coinciden", function (value) {
        return this.parent.password === value;
      }),
  });
  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik<FormikProps>({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: CambiarPasswordSchema,
      onSubmit: handlePress,
    });
  return (
    <Card style={{ marginTop: 20 }}>
      <CardHeader subheader="" title="Cambiar Contraseña" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                error={Boolean(touched.password && errors.password)}
                onBlur={handleBlur("password")}
                onChange={handleChange("password")}
                value={values.password}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(true)}
                      onMouseDown={() => setShowPassword(false)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirmar Contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                onBlur={handleBlur("confirmPassword")}
                onChange={handleChange("confirmPassword")}
                value={values.confirmPassword}
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(true)}
                      onMouseDown={() => setShowConfirmPassword(false)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {errors.confirmPassword && touched.confirmPassword && (
              <Typography style={{ fontSize: 12, color: "red" }}>
                {errors.confirmPassword}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};

export default CambiarPassword;
