import { Logout } from "@mui/icons-material";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthData } from "../../reducers/auth.reducer";
import { NavItem } from "../NavItem";
const items = [
  {
    href: "/",
    icon: <HomeIcon fontSize="small" />,
    title: "Inicio",
  },
  {
    href: "/AdministrarUsuarios",
    icon: <SupervisorAccountIcon fontSize="small" />,
    title: "Administar Usuarios",
  },
  {
    href: "/AdministarImpuestos",
    icon: <ReceiptIcon fontSize="small" />,
    title: "Administrar Impuestos",
  },
  {
    href: "/RecordarImpuestos",
    icon: <NotificationsIcon fontSize="small" />,
    title: "Recordar Impuestos",
  },
  {
    href: "/ComprobantesDePagos",
    icon: <PaymentsIcon fontSize="small" />,
    title: "Solicitudes de Boletas",
  },
  {
    href: "/SolicitudesDeBaja",
    icon: <GroupRemoveIcon fontSize="small" />,
    title: "Solicitudes de Bajas",
  },
];

export const DashboardSidebar = (props: any) => {
  const { open, onClose } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const lgUp = useMediaQuery(() => theme.breakpoints.up("lg"));
  useEffect(
    () => {
      if (!location.pathname) {
        return;
      }

      if (open) {
        onClose?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const handleLogout = () => {
    dispatch(
      setAuthData({
        type: "[SET] user auth",
        payload: null,
      })
    );
    navigate("/Login");
  };
  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}></Box>
          <Box sx={{ px: 2 }}>
            <Typography color="background.default" variant="body1">
              DyR Admin
            </Typography>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "background.defaul",
            my: 3,
          }}
        />
        <Box style={{}} sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
        <Divider sx={{ borderColor: "background.defaul" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Button
            variant="text"
            startIcon={<Logout />}
            onClick={() => handleLogout()}
            fullWidth
            sx={{
              color: "background.default",
            }}
          >
            Cerrar Sesión
          </Button>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
