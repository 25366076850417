import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { showAlert } from "../../../actions/alert.actions";
import {
  crearImpuestoIrpRsp,
  modificarImpuestoIrpRsp,
} from "../../../apis/ApiIrpRsp";
import {
  downloadPdf,
  formatearNumeroConSeparador,
  getBase64,
  quitarSeparadoresDeMilesYConvertirAEntero,
} from "../../../helpers/utils";
import { AlertPropsDto } from "../../../models/dto/AlertProps";
import { ListarTiposImpuestosResponse } from "../../../models/response/tipoImpuestosResponse";
import { AppDispatch } from "../../../store/store";
export interface FormikProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  incomeVatExclude: string;
  expenseTaxableActivity: string;
  netRent: string;
  irpRspTotalExpense: string;
  personalExpense: string;
  investments: string;
  notTaxableIncome: string;
  estimatePit: string;
  description: string;
  status: string;
  fileIrpRsp: string;
  fileNameIrpRsp: string;
  fileExtensionIrpRsp: string;
  fileLibroIrp: string;
  fileNameLibroIrp: string;
  fileExtensionLibroIrp: string;
  fechaAdd: string;
  fechaUpdate: string;
  fechaDelete: string;
}

interface SeccionIRPRSProps {
  id: number;
  idProducto: number;
  month: string;
  year: string;
  status: string;
  impuesto: ListarTiposImpuestosResponse;
  setAlertProps: (alertProps: AlertPropsDto) => void;
}
export const SeccionIRPRSP = ({
  id,
  idProducto,
  month,
  year,
  status,
  impuesto,
  setAlertProps,
}: SeccionIRPRSProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtensionIrpRsp: e.target.files[0].type,
        fileNameIrpRsp: e.target.files[0].name,
        fileIrpRsp: base64,
      });
    }
  };
  const handleLibroChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtensionLibroIrp: e.target.files[0].type,
        fileNameLibroIrp: e.target.files[0].name,
        fileLibroIrp: base64,
      });
    }
  };

  const handlePress = async () => {
    setLoading(true);
    if (values.description === "POST") {
      const response = await crearImpuestoIrpRsp({
        idProducto: values.idProducto,
        month: values.month,
        year: values.year,
        incomeVatExclude: quitarSeparadoresDeMilesYConvertirAEntero(
          values.incomeVatExclude
        ),
        expenseTaxableActivity: quitarSeparadoresDeMilesYConvertirAEntero(
          values.expenseTaxableActivity
        ),
        netRent: quitarSeparadoresDeMilesYConvertirAEntero(values.netRent),
        irpRspTotalExpense: quitarSeparadoresDeMilesYConvertirAEntero(
          values.irpRspTotalExpense
        ),
        personalExpense: quitarSeparadoresDeMilesYConvertirAEntero(
          values.personalExpense
        ),
        investments: quitarSeparadoresDeMilesYConvertirAEntero(
          values.investments
        ),
        notTaxableIncome: quitarSeparadoresDeMilesYConvertirAEntero(
          values.notTaxableIncome
        ),
        estimatePit: quitarSeparadoresDeMilesYConvertirAEntero(
          values.estimatePit
        ),
        description: "",
        status: values.status,
        fileIrpRsp: values.fileIrpRsp,
        fileNameIrpRsp: values.fileNameIrpRsp,
        fileExtensionIrpRsp: values.fileExtensionIrpRsp,
        fileLibroIrp: values.fileLibroIrp,
        fileNameLibroIrp: values.fileNameLibroIrp,
        fileExtensionLibroIrp: values.fileExtensionLibroIrp,
        fechaAdd: values.fechaAdd,
        fechaUpdate: values.fechaUpdate,
        fechaDelete: values.fechaDelete,
        id: values.id,
      });
      if (response) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
        setValues({
          ...values,
          description: "",
          id: response.id,
        });
      }
    } else {
      const modificarImpuesto = await modificarImpuestoIrpRsp(
        {
          idProducto: values.idProducto,
          month: values.month,
          year: values.year,
          incomeVatExclude: quitarSeparadoresDeMilesYConvertirAEntero(
            values.incomeVatExclude
          ),
          expenseTaxableActivity: quitarSeparadoresDeMilesYConvertirAEntero(
            values.expenseTaxableActivity
          ),
          netRent: quitarSeparadoresDeMilesYConvertirAEntero(values.netRent),
          irpRspTotalExpense: quitarSeparadoresDeMilesYConvertirAEntero(
            values.irpRspTotalExpense
          ),
          personalExpense: quitarSeparadoresDeMilesYConvertirAEntero(
            values.personalExpense
          ),
          investments: quitarSeparadoresDeMilesYConvertirAEntero(
            values.investments
          ),
          notTaxableIncome: quitarSeparadoresDeMilesYConvertirAEntero(
            values.notTaxableIncome
          ),
          estimatePit: quitarSeparadoresDeMilesYConvertirAEntero(
            values.estimatePit
          ),
          description: "",
          status: values.status,
          fileIrpRsp: values.fileIrpRsp,
          fileNameIrpRsp: values.fileNameIrpRsp,
          fileExtensionIrpRsp: values.fileExtensionIrpRsp,
          fileLibroIrp: values.fileLibroIrp,
          fileNameLibroIrp: values.fileNameLibroIrp,
          fileExtensionLibroIrp: values.fileExtensionLibroIrp,
          fechaAdd: values.fechaAdd,
          fechaUpdate: values.fechaUpdate,
          fechaDelete: values.fechaDelete,
          id: values.id,
        },
        values.id.toString()
      );
      if (modificarImpuesto) {
        dispatch(
          showAlert({
            showAlert: true,
            title: "Guardado exitosamente!",
            type: "success",
          })
        );
      }
    }
    setLoading(false);
  };

  const IRPGCdSchema = Yup.object().shape({
    incomeVatExclude: Yup.string().required("Este campo es requerido"),
    expenseTaxableActivity: Yup.string().required("Este campo es requerido"),
    netRent: Yup.string().required("Este campo es requerido"),
    irpRspTotalExpense: Yup.string().required("Este campo es requerido"),
    personalExpense: Yup.string().required("Este campo es requerido"),
    investments: Yup.string().required("Este campo es requerido"),
    notTaxableIncome: Yup.string().required("Este campo es requerido"),
    estimatePit: Yup.string().required("Este campo es requerido"),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      id: impuesto.id,
      idProducto: impuesto.idProducto,
      month: month,
      year: year,
      incomeVatExclude: impuesto.incomeVatExclude!.toString(),
      expenseTaxableActivity: impuesto.expenseTaxableActivity!.toString(),
      netRent: impuesto.netRent!.toString(),
      irpRspTotalExpense: impuesto.irpRspTotalExpense!.toString(),
      personalExpense: impuesto.personalExpense!.toString(),
      investments: impuesto.investments!.toString(),
      notTaxableIncome: impuesto.notTaxableIncome!.toString(),
      estimatePit: impuesto.estimatePit!.toString(),
      description: impuesto.description!,
      status: status,
      fileIrpRsp: impuesto.fileIrpRsp ? impuesto.fileIrpRsp : "",
      fileNameIrpRsp: impuesto.fileNameIrpRsp ? impuesto.fileNameIrpRsp : "",
      fileExtensionIrpRsp: impuesto.fileExtensionIrpRsp
        ? impuesto.fileExtensionIrpRsp
        : "",
      fileLibroIrp: impuesto.fileLibroIrp ? impuesto.fileLibroIrp : "",
      fileNameLibroIrp: impuesto.fileNameLibroIrp
        ? impuesto.fileNameLibroIrp
        : "",
      fileExtensionLibroIrp: impuesto.fileExtensionLibroIrp
        ? impuesto.fileExtensionLibroIrp
        : "",
      fechaAdd: "",
      fechaUpdate: "",
      fechaDelete: "",
    },
    validationSchema: IRPGCdSchema,
    onSubmit: handlePress,
  });

  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Sección IRP-RSP" />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.incomeVatExclude && errors.incomeVatExclude
                )}
                helperText={touched.incomeVatExclude && errors.incomeVatExclude}
                label="Ingresos Gravados (IVA excluido)"
                onChange={handleChange("incomeVatExclude")}
                onBlur={handleBlur("incomeVatExclude")}
                required
                value={formatearNumeroConSeparador(values.incomeVatExclude)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.notTaxableIncome && errors.notTaxableIncome
                )}
                helperText={touched.notTaxableIncome && errors.notTaxableIncome}
                label="Ingresos no gravados"
                onChange={handleChange("notTaxableIncome")}
                onBlur={handleBlur("notTaxableIncome")}
                required
                value={formatearNumeroConSeparador(values.notTaxableIncome)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.irpRspTotalExpense && errors.irpRspTotalExpense
                )}
                helperText={
                  touched.irpRspTotalExpense && errors.irpRspTotalExpense
                }
                label="Total egresos"
                onChange={handleChange("irpRspTotalExpense")}
                onBlur={handleBlur("irpRspTotalExpense")}
                required
                value={formatearNumeroConSeparador(values.irpRspTotalExpense)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.expenseTaxableActivity &&
                    errors.expenseTaxableActivity
                )}
                helperText={
                  touched.expenseTaxableActivity &&
                  errors.expenseTaxableActivity
                }
                label="Gastos afectados a la actividad gravada"
                onChange={handleChange("expenseTaxableActivity")}
                onBlur={handleBlur("expenseTaxableActivity")}
                required
                value={formatearNumeroConSeparador(
                  values.expenseTaxableActivity
                )}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(
                  touched.personalExpense && errors.personalExpense
                )}
                helperText={touched.personalExpense && errors.personalExpense}
                label="Gastos personales y familiares"
                onChange={handleChange("personalExpense")}
                onBlur={handleBlur("personalExpense")}
                required
                value={formatearNumeroConSeparador(values.personalExpense)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.investments && errors.investments)}
                helperText={touched.investments && errors.investments}
                label="Inversiones"
                onChange={handleChange("investments")}
                onBlur={handleBlur("investments")}
                required
                value={formatearNumeroConSeparador(values.investments)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.netRent && errors.netRent)}
                helperText={touched.netRent && errors.netRent}
                label="Renta neta"
                onChange={handleChange("netRent")}
                onBlur={handleBlur("netRent")}
                required
                value={formatearNumeroConSeparador(values.netRent)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Box className="">
              <TextField
                fullWidth
                error={Boolean(touched.estimatePit && errors.estimatePit)}
                helperText={touched.estimatePit && errors.estimatePit}
                label="Estimado IRP-RSP del mes"
                onChange={handleChange("estimatePit")}
                onBlur={handleBlur("estimatePit")}
                required
                value={formatearNumeroConSeparador(values.estimatePit)}
                variant="outlined"
                disabled={loading}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                component="label"
                endIcon={<CloudUploadIcon />}
              >
                Subir reporte
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
              <Box sx={{ marginLeft: 1 }}>
                {values.fileIrpRsp && (
                  <Button
                    onClick={() =>
                      downloadPdf(values.fileNameIrpRsp, values.fileIrpRsp)
                    }
                    variant="text"
                    size="small"
                  >
                    {values.fileNameIrpRsp
                      ? values.fileNameIrpRsp
                      : "Ver documento"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                component="label"
                endIcon={<CloudUploadIcon />}
              >
                Subir Libro Egr.
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  onChange={handleLibroChange}
                />
              </Button>
              <Box sx={{ marginLeft: 1 }}>
                {values.fileLibroIrp && (
                  <Button
                    onClick={() =>
                      downloadPdf(values.fileNameLibroIrp, values.fileLibroIrp)
                    }
                    variant="text"
                    size="small"
                  >
                    {values.fileNameLibroIrp
                      ? values.fileNameLibroIrp
                      : "Ver documento"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};
