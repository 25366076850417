import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const pageNotFound = require("../images/no.jpg");
const NotFoundPage = () => {
  const navigation = useNavigate();
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <img
                alt=""
                src={pageNotFound}
                style={{
                  marginTop: 0,
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 560,
                }}
              />
            </Box>
            <Typography align="center" color="textPrimary" variant="h1">
              404: La página que buscas no está aquí
            </Typography>
            <Button
              component="a"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained"
              onClick={() => navigation("/Login")}
            >
              Volver al login
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFoundPage;
