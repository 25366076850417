import { ResumenDetailsDto } from "../models/dto/ResumenDetails";
import API from "./API";
interface ResumenDetailsResponse {
  id: number;
}
export const crearResumenDetails = async (irpData: ResumenDetailsDto) => {
  try {
    const crearResumenDetailsRspResponse =
      await API.post<ResumenDetailsResponse>(
        `/api/resumendetails/crear`,
        irpData
      );
    if (crearResumenDetailsRspResponse.status === 200) {
      return crearResumenDetailsRspResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const modificarResumenDetails = async (
  irpData: ResumenDetailsDto,
  id: string
) => {
  try {
    const modificarResumenDetailsRspResponse = await API.put(
      `/api/resumendetails/${id}/modificar`,
      irpData
    );
    if (modificarResumenDetailsRspResponse.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
