import { FinancialDto } from "../models/dto/Financial.dto";
import API from "./API";

interface FinancialResponse {
  id: number;
}
export const crearFinancial = async (financialData: FinancialDto) => {
  try {
    const financialResponse = await API.post<FinancialResponse>(
      `/api/financial/crear`,
      financialData
    );
    if (financialResponse.status === 200) {
      return financialResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const modificarFinancial = async (
  financialData: FinancialDto,
  idUsuario: string
) => {
  try {
    const financialResponse = await API.put(
      `/api/financial/${idUsuario}/modificar`,
      financialData
    );
    if (financialResponse.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
