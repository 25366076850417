import { CrearUsuarioDto, EditarUsuarioDto } from "../models/dto/Usuarios.dto";
import { UsuariosResponse } from "../models/response/usuariosResponse";
import API from "./API";

export const listarUsuarios = async () => {
  try {
    const usuarios = await API.get<UsuariosResponse[]>("/api/usuarios/listar");
    if (usuarios.status === 200) {
      return usuarios.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const editarUsuario = async (user: EditarUsuarioDto) => {
  try {
    const modificarUsuario = await API.put<string>(
      "/api/usuarios/modificar",
      user
    );
    if (modificarUsuario.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};

export const crearUsuario = async (user: CrearUsuarioDto) => {
  try {
    const modificarUsuario = await API.post<string>(
      "/api/usuarios/crear",
      user
    );
    if (modificarUsuario.status === 201) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};

interface CambiarPasswordDto {
  id: number;
  newPassword: string;
}
export const modificarPassword = async (password: CambiarPasswordDto) => {
  try {
    const modificarUsuario = await API.put<string>(
      "/api/usuarios/modificar/password",
      password
    );
    if (modificarUsuario.status === 202) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};

interface EnviarNotificacionProps {
  titulo: string;
  mensaje: string;
  token: string;
}
export const enviarNotificacion = async (data: EnviarNotificacionProps) => {
  try {
    const enviarNotificacionResult = await API.post<string>(
      "/api/usuarios/enviar/notificacion",
      data
    );
    if (enviarNotificacionResult.status === 201) {
      return true;
    }
    return null;
  } catch {
    return null;
  }
};
