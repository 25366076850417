import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../apis/API";
import { listarUsuarios } from "../apis/ApiUsuarios";
import { LoginResponse } from "../models/response/loginResponse";
import { setAuthData } from "../reducers/auth.reducer";
import { setUserData } from "../reducers/userData.reducer";

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (user: string, password: string) => {
    dispatch(
      setAuthData({
        type: "[SET] auth loading",
        payload: true,
      })
    );
    const loginResponse = await API.post<LoginResponse>(
      "/api/usuarios/owner/login",
      {
        user,
        password,
      }
    );
    if (loginResponse.status === 200) {
      const userList = await listarUsuarios();
      if (userList) {
        dispatch(
          setUserData({
            type: "[SET] user list data",
            payload: userList,
          })
        );
      }
      dispatch(
        setAuthData({
          type: "[SET] user auth",
          payload: loginResponse.data,
        })
      );
      navigate("/");
      dispatch(
        setAuthData({
          type: "[SET] auth loading",
          payload: false,
        })
      );
      return true;
    }
    dispatch(
      setAuthData({
        type: "[SET] auth loading",
        payload: false,
      })
    );
    return false;
  };

  return {
    login,
  };
};
