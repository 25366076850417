import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../Users/Users.css";
const UserPage = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={3} sm={6} xs={12}>
        <Box className="border">
          <Typography>Box 1</Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Box className="border">
          <Typography>Box 2</Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Box className="border">
          <Typography>Box 2</Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Box className="border">
          <Typography>Box 3</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserPage;
