import {
  ListarTiposImpuestosResponse,
  TipoImpuestosPorUsuarioResponse,
} from "../models/response/tipoImpuestosResponse";
import API from "./API";

export const productoImpuestosPorUsuario = async (id: string) => {
  try {
    const tipoImpuestosResponse = await API.get<
      TipoImpuestosPorUsuarioResponse[]
    >(`/api/productoimpuestos/usuario/${id}/datos`);
    if (tipoImpuestosResponse.status === 200) {
      return tipoImpuestosResponse.data;
    }
    if (tipoImpuestosResponse.status === 204) {
      return [];
    }
    return null;
  } catch {
    return null;
  }
};

interface CrearProductoImpuesto {
  idUser: number;
  idTImpuesto: number;
}
export const crearProductoImpuesto = async (
  crearImpuestp: CrearProductoImpuesto
) => {
  try {
    const tipoImpuestosResponse = await API.post(
      `/api/productoimpuestos/crear`,
      crearImpuestp
    );
    if (tipoImpuestosResponse.status === 201) {
      return true;
    }

    return null;
  } catch {
    return null;
  }
};

interface ProductoImpuestoListarDto {
  idCliente: number;
  mes: string;
  anho: string;
}

export const productoimpuestosListar = async (
  listar: ProductoImpuestoListarDto
) => {
  try {
    const tipoImpuestosResponse = await API.get<ListarTiposImpuestosResponse[]>(
      `/api/productoimpuestos/listar/${listar.idCliente}/${listar.mes}/${listar.anho}/impuesto/periodo`
    );
    if (tipoImpuestosResponse.status === 200) {
      return tipoImpuestosResponse.data;
    }
    if (tipoImpuestosResponse.status === 204) {
      return [];
    }
    return null;
  } catch {
    return null;
  }
};
