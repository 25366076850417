import { Box, CircularProgress, Container } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { productoimpuestosListar } from "../../apis/ApiProductoImpuestos";
import { AlertModal } from "../../components/AlertModal";
import ErrorApi from "../../components/errorApi";
import { RenderIf } from "../../components/renderIf";
import { AlertPropsDto } from "../../models/dto/AlertProps";
import { TipoImpuestosEnum } from "../../models/enums/TipoImpuestos.enum";
import { ListarTiposImpuestosResponse } from "../../models/response/tipoImpuestosResponse";
import { RootState } from "../../store/store";
import "../AdministrarImpuestos/AdministrarImpuestos.css";
import { DatosUsuarios } from "./components/DatosUsuario";
import { ResumenDetails } from "./components/ResumenDetails";
import { SeccionFinancial } from "./components/SeccionFinancial";
import { SeccionIRPGC } from "./components/SeccionIRP-GC";
import { SeccionIRPRSP } from "./components/SeccionIRP-RSP";

export interface UserLabelProps {
  id: number;
  label: string;
}
export interface FormikPropsDatosUsuarios {
  id: number;
  month: string;
  year: string;
  status: string;
  user: UserLabelProps | null;
}
const AdministrarImpuestosByIdPage = () => {
  const { userList } = useSelector((state: RootState) => state.userData);
  const { alert } = useSelector((state: RootState) => state.alert);

  const [loading, setLoading] = useState(false);
  const [labelOptions, setLabelOptions] = useState([{ id: 0, label: "" }]);
  const [readOnly, setReadOnly] = useState(false);
  const [listarImpuestos, setListarImpuestos] = useState<
    ListarTiposImpuestosResponse[] | null
  >(null);
  const [alertProps, setAlertProps] = useState<AlertPropsDto>({
    type: "success",
    title: "",
    showAlert: false,
  });
  let { userId } = useParams();

  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = async () => {
    if (userId) {
      const findUser =
        userList && userList?.find((user) => user.id.toString() === userId);
      if (findUser)
        setValues({
          ...values,
          id: findUser.id,
          user: {
            id: findUser.id,
            label: findUser.username,
          },
        });
      setReadOnly(true);
    } else {
      if (userList) {
        setReadOnly(false);
        let arr: any = [];
        userList &&
          userList.map((user, index) => {
            return arr.push({ id: user.id, label: user.username });
          });
        setLabelOptions([...arr]);
      }
    }
  };

  const returnTipoImpuestoComponent = (
    listado: ListarTiposImpuestosResponse
  ) => {
    switch (listado.idTipoImpuesto) {
      case TipoImpuestosEnum.IRP_GC:
        return (
          <SeccionIRPGC
            id={values.id}
            idProducto={listado.idProducto}
            month={values.month}
            year={values.year}
            status={values.status}
            alertProps={alertProps}
            setAlertProps={setAlertProps}
            impuesto={listado}
          />
        );
      case TipoImpuestosEnum.IRP_RSP:
        return (
          <SeccionIRPRSP
            id={values.id}
            idProducto={listado.idProducto}
            month={values.month}
            year={values.year}
            status={values.status}
            impuesto={listado}
            setAlertProps={setAlertProps}
          />
        );
      case TipoImpuestosEnum.FINANCIAL:
        return (
          <SeccionFinancial
            id={values.id}
            idProducto={listado.idProducto}
            month={values.month}
            year={values.year}
            status={values.status}
            impuesto={listado}
            setAlertProps={setAlertProps}
          />
        );
      case TipoImpuestosEnum.RESUMEN_DETAILS:
        return (
          <ResumenDetails
            id={values.id}
            idProducto={listado.idProducto}
            month={values.month}
            year={values.year}
            status={values.status}
            setAlertProps={setAlertProps}
            impuesto={listado}
          />
        );
      default:
        return <></>;
    }
  };

  const hadleChangueUsuario = async (user: UserLabelProps | null) => {
    if (!user) return;
    setLoading(true);
    // const response = await productoImpuestosPorUsuario(user.id.toString());
    // if (response) setTiposImpuestos(response);
    setValues({ ...values, id: user.id, user: user });
    setLoading(false);
  };
  const handlePress = async () => {
    setLoading(true);
    const response = await productoimpuestosListar({
      idCliente: values.id,
      mes: values.month,
      anho: values.year,
    });
    if (response) setListarImpuestos(response);
    setLoading(false);
  };
  const handleChangePeriodo = (periodo: string) => {
    const periodoArr = periodo.split("-");
    setValues({
      ...values,
      month: periodoArr[1],
      year: periodoArr[0],
    });
  };
  const UsuarioSelectedSchema = Yup.object().shape({
    user: Yup.object().required("Favor seleccione un usuario").nullable(),
  });
  const { setValues, values, handleSubmit } =
    useFormik<FormikPropsDatosUsuarios>({
      initialValues: {
        id: 0,
        month: moment(new Date()).format("MM"),
        year: moment(new Date()).format("YYYY"),
        status: "active",
        user: null,
      },
      validationSchema: UsuarioSelectedSchema,
      onSubmit: handlePress,
    });
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        {alert.showAlert && (
          <AlertModal type={alert.type} title={alert.title} />
        )}
        <DatosUsuarios
          values={values}
          labelOptions={labelOptions}
          readOnly={readOnly}
          setValues={setValues}
          hadleChangueUsuario={hadleChangueUsuario}
          handleChangePeriodo={handleChangePeriodo}
          handleSubmit={handleSubmit}
        />
        <RenderIf isTrue={loading === false}>
          <>
            <RenderIf
              isTrue={listarImpuestos !== null && listarImpuestos.length > 0}
            >
              <>
                {listarImpuestos &&
                  listarImpuestos.map((tipoImpuesto, index) =>
                    returnTipoImpuestoComponent(tipoImpuesto)
                  )}
              </>
            </RenderIf>
            <RenderIf
              isTrue={listarImpuestos !== null && listarImpuestos.length <= 0}
            >
              <ErrorApi title="El usuario seleccionado no posee ningún tipo de impuestos" />
            </RenderIf>
          </>
        </RenderIf>
        <RenderIf isTrue={loading === true}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
            component="div"
          >
            <CircularProgress />
          </Box>
        </RenderIf>
      </Container>
    </Box>
  );
};

export default AdministrarImpuestosByIdPage;
