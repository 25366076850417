import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import { modificarBoletaDePago } from "../apis/ApiBoletas";
import { downloadPdf, getBase64 } from "../helpers/utils";
import { AlertPropsDto } from "../models/dto/AlertProps";
import { listarBoletasResponse } from "../models/response/boletasResponse";

interface ModalUsuarioProps {
  open: boolean;
  boletaDePago: listarBoletasResponse | null;
  alertProps: AlertPropsDto;
  setAlertProps: (alertProps: AlertPropsDto) => void;
  handleClose: () => void;
}

export interface FormikProps {
  idUsuario: number;
  idTipoImpuesto: number;
  boletaNumero: string;
  description: string;
  status: string;
  fileBoleta: string;
  fileNameBoleta: string;
  fileExtensionBoleta: string;
}

export const ModalEditarBoletaDePago = ({
  open,
  boletaDePago,
  alertProps,
  setAlertProps,
  handleClose,
}: ModalUsuarioProps) => {
  const [loading, setLoading] = useState(false);
  const sm = useMediaQuery("(min-width:700px)");

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtensionBoleta: e.target.files[0].type,
        fileNameBoleta: e.target.files[0].name,
        fileBoleta: base64.split(",")[1],
      });
    }
  };

  const handlePress = async () => {
    setLoading(true);
    const response = await modificarBoletaDePago(
      values,
      boletaDePago?.id ? boletaDePago.id : 0
    );
    if (response) {
      setAlertProps({
        type: "success",
        showAlert: true,
        title: "Boleta de pago modificada exitosamente.",
      });
    } else {
      setAlertProps({
        type: "error",
        showAlert: true,
        title: "Algo salió mal, intente nuevamente.",
      });
    }
    setTimeout(() => {
      setAlertProps({
        type: "success",
        showAlert: false,
        title: "",
      });
    }, 3000);
    handleClose();
    setLoading(false);
  };
  const handleChangeEstado = (e: string | number | null) => {
    if (e) {
      setValues({
        ...values,
        status: e.toString(),
      });
    }
  };
  const EditarBoletaDePagoSchema = Yup.object().shape({
    description: Yup.string().max(250, "El máximo es de 250 caracteres"),
  });
  useEffect(() => {
    setValues({
      ...values,
      idUsuario: boletaDePago ? boletaDePago?.idUsuario : 0,
      idTipoImpuesto: boletaDePago ? boletaDePago?.idTipoImpuesto : 0,
      boletaNumero: boletaDePago ? boletaDePago?.boletaNumero : "",
      description: boletaDePago ? boletaDePago?.description : "",
      status: boletaDePago ? boletaDePago.status : "",
      fileBoleta: boletaDePago ? boletaDePago.fileBoleta : "",
      fileNameBoleta: boletaDePago ? boletaDePago.fileNameBoleta : "",
      fileExtensionBoleta: boletaDePago ? boletaDePago.fileExtensionBoleta : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boletaDePago]);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      idUsuario: 0,
      idTipoImpuesto: 0,
      boletaNumero: "",
      description: "",
      status: "",
      fileBoleta: "",
      fileNameBoleta: "",
      fileExtensionBoleta: "",
    },
    validationSchema: EditarBoletaDePagoSchema,
    onSubmit: handlePress,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={sm ? style : styleResponsive}>
        <CardHeader
          title="Editar boleta de pago"
          style={{ padding: 18, paddingLeft: 24 }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                value={boletaDePago?.nombreCliente}
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                value={boletaDePago?.userName}
                id="outlined-basic"
                label="Usuario"
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 3 }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur("description")}
                onChange={handleChange("description")}
                value={sm.toString()}
                id="outlined-basic"
                label="Descripcion"
                variant="outlined"
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onBlur={handleBlur("idPerfil")}
                  onChange={(e) => handleChangeEstado(e.target.value)}
                  value={values.status}
                  label="Estado"
                  disabled={loading}
                >
                  <MenuItem value="active">Activo</MenuItem>
                  <MenuItem value="inactive">Inactivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Subir archivo
                  <input
                    hidden
                    accept="application/pdf"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
                <Box sx={{ marginLeft: 1 }}>
                  {values.fileBoleta && (
                    <Button
                      variant="text"
                      onClick={() =>
                        downloadPdf(values.fileNameBoleta, values.fileBoleta)
                      }
                      style={{ color: "black" }}
                    >
                      {values.fileNameBoleta}
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          component="div"
          style={{
            display: "flex",
            padding: 12,
            justifyContent: "end",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleClose()}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
};
const styleResponsive = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  margin: "auto",
};
