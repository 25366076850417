import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { listarSolicitudesBaja } from "../../apis/ApiSolicitudBaja";
import { AlertModal } from "../../components/AlertModal";
import { ModalEditarSolicitudDeBaja } from "../../components/ModalEditarSolicitudBaja";
import ErrorApi from "../../components/errorApi";
import { RenderIf } from "../../components/renderIf";
import { deepSearcher } from "../../helpers/utils";
import { AlertPropsDto } from "../../models/dto/AlertProps";
import { SolicitudesBajasListResponse } from "../../models/response/SolicitudesBajasResponse";
interface Column {
  id:
    | "id"
    | "nombreSolicita"
    | "userName"
    | "idUsuarioSolicita"
    | "usuarioModifica"
    | "descripcion"
    | "estado"
    | "editar";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  // { id: "id", label: "id", minWidth: 170 },
  { id: "nombreSolicita", label: "Nombre Solicita", minWidth: 100 },
  {
    id: "userName",
    label: "Usuario",
    minWidth: 170,
    align: "right",
  },
  // {
  //   id: "idUsuarioSolicita",
  //   label: "idUsuarioSolicita",
  //   minWidth: 170,
  //   align: "right",
  // },
  // {
  //   id: "usuarioModifica",
  //   label: "usuarioModifica",
  //   minWidth: 170,
  //   align: "right",
  // },
  { id: "descripcion", label: "descripcion", minWidth: 170, align: "right" },
  { id: "estado", label: "estado", minWidth: 170, align: "right" },
  { id: "editar", label: "editar", minWidth: 170, align: "right" },
];

const SolicitudesDeBajasPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [solicitud, setSolicitud] =
    useState<SolicitudesBajasListResponse | null>(null);
  const [alertProps, setAlertProps] = useState<AlertPropsDto>({
    type: "success",
    title: "",
    showAlert: false,
  });

  const [solicitudesDeBaja, setsolicitudesDeBaja] = useState<
    SolicitudesBajasListResponse[] | null
  >(null);
  const [filteredItems, setFilteredItems] = useState<
    SolicitudesBajasListResponse[] | null
  >(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInitialState();
  }, []);

  useEffect(() => {
    if (alertProps.type === "success" && alertProps.showAlert)
      getInitialState();
  }, [alertProps]);

  const getInitialState = async () => {
    setLoading(true);
    const solicitudesResponse = await listarSolicitudesBaja();
    if (solicitudesResponse) {
      setsolicitudesDeBaja(solicitudesResponse);
      setFilteredItems(solicitudesResponse);
    }
    setLoading(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handlePressEditarBoleta = (
    solicitudSelected: SolicitudesBajasListResponse
  ) => {
    setSolicitud(solicitudSelected);
    setShowModal(true);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    const filteredItems =
      solicitudesDeBaja &&
      solicitudesDeBaja.filter(
        deepSearcher(["nombreSolicita", "userName", "estado"], query)
      );
    setFilteredItems(filteredItems);
  };
  const handleRefresh = () => getInitialState();

  return (
    <>
      <RenderIf isTrue={loading === false}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            {alertProps.showAlert && (
              <AlertModal type={alertProps.type} title={alertProps.title} />
            )}
            <RenderIf isTrue={solicitudesDeBaja !== null}>
              <>
                <ModalEditarSolicitudDeBaja
                  open={showModal}
                  solicitudDeBaja={solicitud}
                  handleClose={() => setShowModal(false)}
                  alertProps={alertProps}
                  setAlertProps={setAlertProps}
                />
                <Card>
                  <CardHeader title="Solicitudes de bajas" />
                  <Divider />
                  <CardContent>
                    <Grid container style={{ marginBottom: 16 }} spacing={2}>
                      <Grid lg={4} md={6} sm={12} xs={12} item>
                        <TextField
                          placeholder="Buscar..."
                          size="small"
                          value={searchQuery}
                          onChange={(event) =>
                            onChangeSearch(event.target.value)
                          }
                        />
                      </Grid>
                      <Grid lg={8} md={6} sm={12} xs={12} item>
                        <Box
                          component="div"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<RefreshIcon />}
                            style={{ marginRight: 10 }}
                            onClick={() => handleRefresh()}
                          >
                            Actualizar
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredItems &&
                              filteredItems
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                    >
                                      {columns.map((column) => {
                                        let value;
                                        if (column.id === "editar") {
                                          return (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                            >
                                              <Button
                                                size="small"
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                onClick={() =>
                                                  handlePressEditarBoleta(row)
                                                }
                                              >
                                                Modificar
                                              </Button>
                                            </TableCell>
                                          );
                                        } else {
                                          value = row[column.id];
                                        }
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={solicitudesDeBaja ? solicitudesDeBaja.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página"
                      />
                    </Paper>
                  </CardContent>
                </Card>
              </>
            </RenderIf>
            <RenderIf isTrue={solicitudesDeBaja === null}>
              <ErrorApi handleClick={() => {}} />
            </RenderIf>
          </Container>
        </Box>
      </RenderIf>
      <RenderIf isTrue={loading === true}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            {alertProps.showAlert && (
              <AlertModal type={alertProps.type} title={alertProps.title} />
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                height: "calc(100vh - 64px)",
                alignItems: "center",
              }}
              component="div"
            >
              <CircularProgress />
            </Box>
          </Container>
        </Box>
      </RenderIf>
    </>
  );
};

export default SolicitudesDeBajasPage;
