import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { editarUsuario, listarUsuarios } from "../../apis/ApiUsuarios";
import { convertNumber } from "../../helpers/utils";
import { UsuariosResponse } from "../../models/response/usuariosResponse";
import { setUserData } from "../../reducers/userData.reducer";

interface StatesProps {
  value: number;
  label: string;
}
const states: StatesProps[] = [
  {
    value: 0,
    label: "Activo",
  },
  {
    value: 1,
    label: "Inactivo",
  },
];
const tiposDeclaracion: StatesProps[] = [
  {
    value: 0,
    label: "Mensual",
  },
  {
    value: 1,
    label: "Anual",
  },
];
const tiposIdPerfil: StatesProps[] = [
  {
    value: 1,
    label: "Administrador",
  },
  {
    value: 2,
    label: "Usuario",
  },
];
interface EditUserProps {
  id: number;
  name: string;
  email: string;
  telefono: number;
  numeroDocumento: string;
  digitoVerificador: string;
  fechaVencimiento: string;
  tipoDeclaracion: number;
  estadoUsuario: number;
  idPerfil: number;
  firebaseToken: string;
}
interface AccountProfileDetailsProps {
  user: UsuariosResponse | null;
  setShowAlert: (showAlert: string) => void;
}

export const AccountProfileDetails = ({
  user,
  setShowAlert,
}: AccountProfileDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const getUserData = () => {
    if (user) {
      setValues({
        ...values,
        id: user.id,
        name: user.name,
        email: user.email,
        telefono: user.telefono,
        estadoUsuario: user.estado === "active" ? 0 : 1,
        idPerfil: user.idPerfil,
        firebaseToken: user.firebaseToken ? user.firebaseToken : "",
        numeroDocumento: user.numeroDocumento,
        fechaVencimiento: user.fechaVencimiento,
        tipoDeclaracion: user.tipoDeclaracion === "mensual" ? 0 : 1,
        digitoVerificador: user.digitoVerificador,
      });
    }
  };
  const handlePress = async () => {
    setLoading(true);
    const editarUsuarioResponse = await editarUsuario({
      id: values.id,
      name: values.name,
      email: values.email,
      telefono: values.telefono,
      estadoUsuario: values.estadoUsuario,
      idPerfil: values.idPerfil,
      firebaseToken: values.firebaseToken,
      digitoVerificador: values.digitoVerificador,
      fechaVencimiento: moment(values.fechaVencimiento).format(),
      numeroDocumento: values.numeroDocumento,
      tipoDeclaracion: values.tipoDeclaracion,
    });
    if (!editarUsuarioResponse) setShowAlert("error");
    if (editarUsuarioResponse) {
      setShowAlert("success");
      dispatch(
        setUserData({
          type: "[SET] user list loading",
          payload: true,
        })
      );
      const userList = await listarUsuarios();
      if (userList) {
        dispatch(
          setUserData({
            type: "[SET] user list data",
            payload: userList,
          })
        );
      }
      dispatch(
        setUserData({
          type: "[SET] user list loading",
          payload: false,
        })
      );
    }
    setTimeout(() => setShowAlert(""), 3000);
    setLoading(false);
  };

  const EditUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "El minimo es de 2 caracteres")
      .max(50, "El maximo es de 50 caracteres")
      .required("Este campo es obligario"),
    email: Yup.string()
      .min(2, "El minimo es de 2 caracteres")
      .max(50, "El maximo es de 50 caracteres")
      .required("Este campo es obligario")
      .email("Ingrese un correo valido"),
    telefono: Yup.string()
      .min(2, "El minimo es de 2 caracteres")
      .max(10, "El maximo es de 10 caracteres")
      .required("Este campo es obligario"),
    fechaVencimiento: Yup.string()
      .required("Este campo es obligario")
      .nullable(),
    numeroDocumento: Yup.string()
      .required("Este cammpo es obligario")
      .nullable(),
    digitoVerificador: Yup.string()
      .required("Este cammpo es obligario")
      .nullable(),
  });
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<EditUserProps>({
    initialValues: {
      id: 0,
      name: "",
      email: "",
      estadoUsuario: 0,
      telefono: 0,
      idPerfil: 0,
      firebaseToken: "",
      digitoVerificador: "",
      fechaVencimiento: "",
      numeroDocumento: "",
      tipoDeclaracion: 0,
    },
    validationSchema: EditUserSchema,
    onSubmit: handlePress,
  });

  return (
    <form autoComplete="off">
      <Card>
        <CardHeader
          subheader="Esta información puede ser editada"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Nombre"
                name="name"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                required
                value={values.name}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Estado"
                name="estadoUsuario"
                onChange={(e) =>
                  setValues({
                    ...values,
                    estadoUsuario: convertNumber(e.target.value),
                  })
                }
                onBlur={handleBlur("estadoUsuario")}
                required
                select
                SelectProps={{ native: true }}
                value={values?.estadoUsuario}
                variant="outlined"
                disabled={loading}
              >
                {states.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="nro documento"
                name="numeroDocumento"
                error={Boolean(
                  touched.numeroDocumento && errors.numeroDocumento
                )}
                helperText={touched.numeroDocumento && errors.numeroDocumento}
                onChange={handleChange("numeroDocumento")}
                onBlur={handleBlur("numeroDocumento")}
                value={values.numeroDocumento}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="digito verificador"
                name="digitoVerificador"
                error={Boolean(
                  touched.digitoVerificador && errors.digitoVerificador
                )}
                helperText={
                  touched.digitoVerificador && errors.digitoVerificador
                }
                onChange={handleChange("digitoVerificador")}
                onBlur={handleBlur("digitoVerificador")}
                value={values.digitoVerificador}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Fecha de vencimiento"
                name="fechaVencimiento"
                onChange={handleChange("fechaVencimiento")}
                onBlur={handleBlur("fechaVencimiento")}
                value={moment(values.fechaVencimiento).format("YYYY-MM-DD")}
                required
                variant="outlined"
                disabled={loading}
                type="date"
              />
              {errors.fechaVencimiento && (
                <Typography style={{ fontSize: 12 }} color="primary">
                  {errors.fechaVencimiento}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <TextField
                fullWidth
                label="Tipo declaracion"
                name="tipoDeclaracion"
                onChange={(e) =>
                  setValues({
                    ...values,
                    tipoDeclaracion: convertNumber(e.target.value),
                  })
                }
                onBlur={handleBlur("tipoDeclaracion")}
                required
                select
                SelectProps={{ native: true }}
                value={values?.tipoDeclaracion}
                variant="outlined"
                disabled={loading}
              >
                {tiposDeclaracion.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                label="DJ Informativa"
                name="tipoDeclaracion"
                error={Boolean(
                  touched.tipoDeclaracion && errors.tipoDeclaracion
                )}
                helperText={touched.tipoDeclaracion && errors.tipoDeclaracion}
                onChange={handleChange("tipoDeclaracion")}
                onBlur={handleBlur("tipoDeclaracion")}
                value={values.tipoDeclaracion}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Correo"
                name="email"
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                required
                value={values.email}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="telefono"
                name="telefono"
                onChange={handleChange("telefono")}
                onBlur={handleBlur("telefono")}
                value={values.telefono}
                variant="outlined"
                disabled={loading}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Tipo Usuario"
                name="idPerfil"
                onChange={(e) =>
                  setValues({
                    ...values,
                    idPerfil: convertNumber(e.target.value),
                  })
                }
                onBlur={handleBlur("idPerfil")}
                required
                select
                SelectProps={{ native: true }}
                value={values?.idPerfil}
                variant="outlined"
                disabled={loading}
              >
                {tiposIdPerfil.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </form>
  );
};
