import { Alert, Box } from "@mui/material";
import { AlertModalType } from "../models/types/AlertModalTypes";
interface AlertModalProps {
  type: AlertModalType;
  title: string;
}
export const AlertModal = ({ type, title }: AlertModalProps) => {
  return (
    <Box
      component="div"
      style={{
        zIndex: 100,
        position: "sticky",
        top: 64,
        display: "flex",
        width: "100%",
      }}
    >
      <Box
        component="div"
        style={{
          marginBottom: 20,
          width: "100%",
        }}
      >
        <Alert severity={type} style={{ width: "100%" }}>
          {title}
        </Alert>
      </Box>
    </Box>
  );
};
