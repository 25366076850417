import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import alertReducer from "../reducers/alert.reducer";
import authReducer from "../reducers/auth.reducer";
import userDataReducer from "../reducers/userData.reducer";

const appReducer = combineReducers({
  userData: userDataReducer,
  auth: authReducer,
  alert: alertReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
