import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import NotFoundPage from "./pages/404";
import AdministrarImpuestosPage from "./pages/AdministrarImpuestos/AdministrarImpuestos";
import AdministrarImpuestosByIdPage from "./pages/AdministrarImpuestos/AdministrarImpuestosById";
import AdministarUsuariosPage from "./pages/AdministrarUsuarios/AdministarUsuarios";
import EditarUsuarioPage from "./pages/EditarUsuario/EditarUsuario";
import RecordarImpuestosPage from "./pages/RecordarImpuestos/RecordarImpuestos";
import SolicitudesDeBajasPage from "./pages/SolicitudesDeBajas/SolicitudesDeBajas";
import SolicitudesDeBoletasPage from "./pages/SolicitudesDeBoletas/SolicitudesDeBoletas";
import UserPage from "./pages/Users/Users";
import Home from "./pages/home/Home";
import LoginPage from "./pages/login/Login";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/Usuarios" element={<UserPage />} />
          <Route path="/AdministarImpuestos/">
            <Route path="" element={<AdministrarImpuestosPage />} />
            <Route path=":userId" element={<AdministrarImpuestosByIdPage />} />
          </Route>
          <Route
            path="/RecordarImpuestos"
            element={<RecordarImpuestosPage />}
          />
          <Route
            path="/ComprobantesDePagos"
            element={<SolicitudesDeBoletasPage />}
          />
          <Route
            path="/EditarUsuario/:userId"
            element={<EditarUsuarioPage />}
          />
          <Route
            path="/AdministrarUsuarios"
            element={<AdministarUsuariosPage />}
          />
          <Route
            path="/SolicitudesDeBaja"
            element={<SolicitudesDeBajasPage />}
          />
        </Route>
        <Route path="/Login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
