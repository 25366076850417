import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertModalType } from "../models/types/AlertModalTypes";

export interface AlertDto {
  type: AlertModalType;
  showAlert: boolean;
  title: string;
}
interface Alert {
  alert: AlertDto;
}
const initialState: Alert = {
  alert: {
    type: "success",
    showAlert: false,
    title: "",
  },
};
type alertAction = { type: "[SET] show alert"; payload: AlertDto };

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<alertAction>) => {
      switch (action.payload.type) {
        case "[SET] show alert":
          return {
            ...state,
            alert: action.payload.payload,
          };
      }
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
