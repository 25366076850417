import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { getBase64, stringAvatar } from "../helpers/utils";

import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { crearBoletaDePago } from "../apis/ApiBoletas";
import { AlertPropsDto } from "../models/dto/AlertProps";
import { RootState } from "../store/store";

interface ModalUsuarioProps {
  open: boolean;
  alertProps: AlertPropsDto;
  setAlertProps: (alertProps: AlertPropsDto) => void;
  handleClose: () => void;
}

export interface FormikProps {
  idUsuario: string;
  idTipoImpuesto: string;
  boletaNumero: string;
  description: string;
  status: string;
  myFile: string;
  filePath: string;
  fileExtension: string;
}

export const ModalCrearBoletaDePago = ({
  open,
  alertProps,
  setAlertProps,
  handleClose,
}: ModalUsuarioProps) => {
  const { userList } = useSelector((state: RootState) => state.userData);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [labelOptions, setLabelOptions] = useState([{ id: 0, label: "" }]);
  const sm = useMediaQuery("(min-width:700px)");

  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = async () => {
    let arr: any = [];
    userList &&
      userList.map((user, index) => {
        return arr.push({ id: user.id, label: user.username });
      });
    setLabelOptions([...arr]);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      let base64 = await getBase64(e.target.files[0]);
      setValues({
        ...values,
        fileExtension: e.target.files[0].type,
        filePath: e.target.files[0].name,
        myFile: base64,
      });
    }
  };

  const handleChangeUsuario = (idUsuario?: number) => {
    if (idUsuario) {
      setValues({
        ...values,
        idUsuario: idUsuario.toString(),
      });
    }
  };

  const handlePress = async () => {
    setLoading(true);
    const response = await crearBoletaDePago({
      idUsuario: Number(values.idUsuario),
      boletaNumero: values.boletaNumero,
      idTipoImpuesto: Number(values.idTipoImpuesto),
      description: values.description,
      status: values.status,
      fileBoleta: values.myFile,
      fileNameBoleta: values.filePath,
      fileExtensionBoleta: values.fileExtension,
    });
    if (response) {
      setAlertProps({
        type: "success",
        showAlert: true,
        title: "Boleta de pago creada exitosamente.",
      });
    } else {
      setAlertProps({
        type: "error",
        showAlert: true,
        title: "Algo salió mal, intente nuevamente.",
      });
    }
    setTimeout(() => {
      setAlertProps({
        type: "success",
        showAlert: false,
        title: "",
      });
    }, 3000);
    handleClose();
    setLoading(false);
  };
  const handleChangeEstado = (e: string | number | null) => {
    if (e) {
      setValues({
        ...values,
        status: e.toString(),
      });
    }
  };
  const handleTipoImpuesto = (e: string | number | null) => {
    if (e) {
      setValues({
        ...values,
        idTipoImpuesto: e.toString(),
      });
    }
  };
  const EditarBoletaDePagoSchema = Yup.object().shape({
    idUsuario: Yup.string().required("Favor seleccioné un usuario"),
    idTipoImpuesto: Yup.string().required(
      "Favor seleccioné un tipo de impuesto"
    ),
    description: Yup.string().max(250, "El máximo es de 250 caracteres"),
    boletaNumero: Yup.string(),
    status: Yup.string().required("Favor seleccioné un estado"),
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    touched,
  } = useFormik<FormikProps>({
    initialValues: {
      idUsuario: "",
      idTipoImpuesto: "",
      boletaNumero: "",
      description: "",
      status: "",
      myFile: "",
      filePath: "",
      fileExtension: "",
    },
    validationSchema: EditarBoletaDePagoSchema,
    onSubmit: handlePress,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={sm ? style : styleResponsive}>
        <CardHeader
          title="Crear boleta de pago"
          style={{ padding: 18, paddingLeft: 24 }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className="">
                <Autocomplete
                  id="country-select-demo"
                  fullWidth
                  options={labelOptions}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => handleChangeUsuario(value?.id)}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    >
                      <Avatar
                        style={{ width: 30, height: 30 }}
                        {...stringAvatar(option.label, 12)}
                      />
                      <Box component="div" style={{ display: "flex" }}>
                        <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                          {option.label}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleccionar usuario"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.idUsuario && touched.idUsuario && (
                  <Typography style={{ fontSize: 12, color: "red" }}>
                    {errors.idUsuario}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="id-impuesto">Tipo de impuesto</InputLabel>
                <Select
                  labelId="id-impuesto"
                  id="id-impuesto"
                  onBlur={handleBlur("idTipoImpuesto")}
                  onChange={(e) => handleTipoImpuesto(e.target.value)}
                  value={values.idTipoImpuesto}
                  label="Tipo de impuesto"
                  disabled={loading}
                >
                  <MenuItem value="1">IRP GC</MenuItem>
                  <MenuItem value="2">IRP RSP</MenuItem>
                  <MenuItem value="3">FINANCIAL</MenuItem>
                  <MenuItem value="4">RESUMEN DETAlLES</MenuItem>
                </Select>
              </FormControl>
              {errors.idTipoImpuesto && touched.idTipoImpuesto && (
                <Typography style={{ fontSize: 12, color: "red" }}>
                  {errors.idTipoImpuesto}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 3 }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                error={Boolean(touched.boletaNumero && errors.boletaNumero)}
                helperText={touched.boletaNumero && errors.boletaNumero}
                onBlur={handleBlur("boletaNumero")}
                onChange={handleChange("boletaNumero")}
                value={values.boletaNumero}
                id="outlined-basic"
                label="Número de boleta"
                variant="outlined"
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onBlur={handleBlur("status")}
                  onChange={(e) => handleChangeEstado(e.target.value)}
                  value={values.status}
                  label="Estado"
                  disabled={loading}
                >
                  <MenuItem value="active">Activo</MenuItem>
                  <MenuItem value="inactive">Inactivo</MenuItem>
                </Select>
              </FormControl>
              {errors.status && touched.status && (
                <Typography style={{ fontSize: 12, color: "red" }}>
                  {errors.status}
                </Typography>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur("description")}
                onChange={handleChange("description")}
                value={values.description}
                id="outlined-basic"
                label="Descripción"
                variant="outlined"
                disabled={loading}
                // style={{ marginTop: 16 }}
                fullWidth
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Subir archivo
                  <input
                    hidden
                    accept="application/pdf"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
                <Box sx={{ marginLeft: 1 }}>
                  {file && <Typography>{file.name}</Typography>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          component="div"
          style={{
            display: "flex",
            padding: 12,
            justifyContent: "end",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleClose()}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
};

const styleResponsive = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  margin: "auto",
};
