import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store/store";
import { DasboardLayout } from "./Layaout/dashboardLayaout";

export const ProtectedRoutes = () => {
  const { userData } = useSelector((state: RootState) => state.auth);
  if (userData === null) {
    return <Navigate to="/Login" />;
  }
  return (
    <DasboardLayout>
      <Outlet />
    </DasboardLayout>
  );
};
