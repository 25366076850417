import { AlertDto, setAlert } from "../reducers/alert.reducer";
import { AppDispatch } from "../store/store";

export const showAlert = (alert: AlertDto) => {
  return async (dispatch: AppDispatch) => {
    dispatch(
      setAlert({
        type: "[SET] show alert",
        payload: {
          showAlert: alert.showAlert,
          title: alert.title,
          type: alert.type,
        },
      })
    );
    setTimeout(() => {
      dispatch(
        setAlert({
          type: "[SET] show alert",
          payload: {
            showAlert: false,
            title: "",
            type: alert.type,
          },
        })
      );
    }, 3000);
  };
};
