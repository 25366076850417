import axios, { AxiosError, AxiosResponse } from "axios";
import { showAlert } from "../actions/alert.actions";
import { WebConfig } from "../config/config";
import { ErrorResponse } from "../models/response/ErrorResponse";
import { store } from "../store/store";

const API = axios.create({
  timeout: WebConfig.apiTimeout,
  baseURL: WebConfig.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use((config) => {
  return config;
});

API.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  async (error: AxiosError<ErrorResponse>) => {
    const alertDescription =
      error.response?.data.errorDescription ||
      "Algo salió mal, intente de nuevo.";

    switch (error.response?.status) {
      case 401:
        return error;
      default:
        store.dispatch(
          showAlert({ showAlert: true, title: alertDescription, type: "error" })
        );
        return error;
    }
  }
);

export default API;
