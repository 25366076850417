import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Button, Typography } from "@mui/material";
const erroPageLottie = require("../lotties/errorPageLottie.json");

interface ErrorPageProps {
  title?: string;
  handleClick?: () => void;
}

const ErrorApi = ({
  title = "Algo salió mal",
  handleClick,
}: ErrorPageProps) => {
  return (
    <div>
      <Player
        src={erroPageLottie}
        loop
        autoplay
        style={{ height: "250px", width: "250px" }}
      />
      <Typography style={{ fontSize: 24, textAlign: "center" }}>
        {title}
      </Typography>
      {handleClick && (
        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 15 }}
            onClick={handleClick}
          >
            Reintentar
          </Button>
        </Box>
      )}
    </div>
  );
};

export default ErrorApi;
