import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../models/response/loginResponse";

interface authDataDto {
  userData: LoginResponse | null;
  loginLoading: boolean;
}
const initialState: authDataDto = {
  userData: null,
  loginLoading: false,
};
type authDataAction =
  | { type: "[SET] auth loading"; payload: boolean }
  | { type: "[SET] user auth"; payload: LoginResponse | null };

export const authDataSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<authDataAction>) => {
      switch (action.payload.type) {
        case "[SET] user auth":
          return {
            ...state,
            userData: action.payload.payload,
          };
        case "[SET] auth loading":
          return {
            ...state,
            loginLoading: action.payload.payload,
          };
      }
    },
  },
});

export const { setAuthData } = authDataSlice.actions;

export default authDataSlice.reducer;
