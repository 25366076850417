import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AccountProfileDetails } from "../../components/EditarUsuario/account-profile-details";
import CambiarPassword from "../../components/EditarUsuario/CambiarPassword";
import EditarTiposIpuestos from "../../components/EditarUsuario/EditarTiposImpuestos";
import { UsuariosResponse } from "../../models/response/usuariosResponse";
import { RootState } from "../../store/store";
const EditarUsuarioPage = () => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const { userList } = useSelector((state: RootState) => state.userData);
  const [user, setUser] = useState<UsuariosResponse | null>(null);
  const [showAlert, setShowAlert] = useState("");
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = () => {
    const findUser = userList?.find((user) => user.id.toString() === userId);
    if (findUser) setUser(findUser);
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Button
          onClick={() => navigate("/")}
          startIcon={<ArrowBackIcon />}
          size="small"
          variant="contained"
        >
          Volver
        </Button>
        {showAlert === "error" && (
          <Alert severity="error">Algo salió mal, intenta de nuevo.</Alert>
        )}
        {showAlert === "success" && (
          <Alert severity="success">Datos guardados correctamente.</Alert>
        )}
        <Typography sx={{ mb: 3 }} variant="h4">
          Cuenta
        </Typography>
        <AccountProfileDetails user={user} setShowAlert={setShowAlert} />
        <EditarTiposIpuestos user={user} />
        <CambiarPassword user={user} />
      </Container>
    </Box>
  );
};

export default EditarUsuarioPage;
